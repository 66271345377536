import setAttributes from '../../../../commontools/setAttributes.js'
import setStyle from './setStyle.js'

/*
    Example

    new LabledPolygon(svg, {
            points : [  200, 100, 
                        400, 100, 
                        400, 50,
                        500, 50,
                        500, 250,
                        400, 200,
                        400, 300, 
                        150, 350],
            style: "fill: white; fill-opacity: 0; stroke: black; stroke-width: 3px;",
            label : {
                text: "S = 5",
                fontSize: 20,
                shift: {
                    x : 5, y : -5
                }
            },
            edgeLabels : ['a = 5', 'b = 3', 'c = 7', 'd = 8', 'e', 'f', 'g', 'h'],
            edgeLabelFontSize : 30,
            edgeLabelShifts : [  
                        -10, 0, 
                        0, -5, 
                        3, 0,
                        0, 0,
                        0, 0,
                        0, 0,
                        0, 0, 
                        0, 0]
        });

*/

class LabeledPolygon {
    constructor(svg, props){

        /*
            props:
                Обязательные:

                points - массив точек [], 
                        если точки указывать в порядке обхода по часовой стрелке, 
                        метки сторон будут с внешней стороны, если против часовой - с внутренней

                Опциональные:
                style - стиль (объект или текст)
                label: - метка фигуры
                    {
                        Обязательные:
                        text - текст метки
                        fontSize - размер текста метки в px

                        Опциональные:
                        style - стиль текста (объект или текст)
                        point: - размещение метки
                            {x, y}
                        shift: - смещение метки относительно точки пересечения диагоналей
                            {x, y}
                        Если заданы point и shift используется point
                    }
                edgeLabels - массив меток сторон в порядке points
                edgeLabelFontSize - размер шрифта меток сторон, если не задан, берётся label.fontSize
                edgeLabelShifts - массив сдвигов меток для каждой стороны:
                    dX1, dY1, dX2, dY2... в порядке points
        */        
        this.SVGNS = "http://www.w3.org/2000/svg";

        /*
            Задание свойств объекта
        */
        this.svg = svg;
        
        /*
            Создание многоугольника
        */
        this.poly = document.createElementNS(this.SVGNS, 'polygon');
        let points = props.points.join(",");
        let n = Math.floor(props.points.length / 2);
        this.poly.setAttribute("points", points);
        if(props.style){
            setStyle(this.poly, props.style);
        }

        /*
            Размещение меток
        */

        if(props.label || props.edgeLabels){
            this.group = document.createElementNS(this.SVGNS, 'g');
            this.group.appendChild(this.poly);
        }

        // Если задана метка многоугольника
        if(props.label){
            this.polyLabel = document.createElementNS(this.SVGNS, 'text');
            
            if(props.label.text){
                this.polyLabel.innerHTML = props.label.text;
            }
            
            let styleStr = '';
            if(props.label.style){
                styleStr = setStyle(this.polyLabel, props.label.style);
            }

            let fontSize = 20;
            if(props.label.fontSize){
                fontSize = props.label.fontSize;
                if(styleStr.length > 0){
                    styleStr += ';';
                }
                styleStr += 'font-size:' + props.label.fontSize + 'px';
                setStyle(this.polyLabel, styleStr);
            }
            
            let x = Math.floor( (props.points[0] + props.points[n]) / 2);
            if(props.label.point){
                x = props.label.point.x;
            }
            else if(props.label.shift){
                x += props.label.shift.x;
            }

            let y = Math.floor( (props.points[1] + props.points[n+1]) / 2);
            if(props.label.point){
                y = props.label.point.y;
            }
            else if(props.label.shift){
                y += props.label.shift.y;
            }            

            this.placeText(this.polyLabel, fontSize, x, y, 'center', 'center');

            this.group.appendChild(this.polyLabel);
        }

        // Если заданы метки сторон
        if(props.edgeLabels){
            for(let i = 0; i < props.edgeLabels.length && i < n; i++){
                let edgeText = document.createElementNS(this.SVGNS, 'text');
                edgeText.innerHTML = props.edgeLabels[i];
                let fontSize = 20;
                if(props.edgeLabelFontSize){
                    fontSize = props.edgeLabelFontSize;
                }else if(props.label && props.label.fontSize){
                    fontSize = props.label.fontSize;
                }
                edgeText.setAttribute("style", "font-size:" + fontSize + 'px');

                let x1 = props.points[2 * i];
                let y1 = props.points[2 * i + 1];
                
                let i1 = (i + 1) % n;

                let x2 = props.points[2 * i1];
                let y2 = props.points[2 * i1 + 1];

                let x = Math.round((x1 + x2) / 2);
                if(props.edgeLabelShifts){
                    x += props.edgeLabelShifts[2 * i];
                }

                let y = Math.round((y1 + y2) / 2);
                if(props.edgeLabelShifts){
                    y += props.edgeLabelShifts[2 * i + 1];
                }

                let alignV = 'center';

                if(x2 > x1){
                    alignV = 'end';
                    y = Math.round(y - fontSize * 0.3);
                }
                else if(x1 > x2){
                    alignV = 'begin';
                    y = Math.round(y + fontSize * 0.1);
                }

                let alignH = 'center';

                if(y2 > y1){
                    alignH = 'begin';
                    x = Math.round(x + fontSize * 0.3);                    
                }
                else if(y1 > y2){
                    alignH = 'end';
                    x = Math.round(x - fontSize * 0.3);   
                }

                this.placeText(edgeText, fontSize, x, y, alignH, alignV);

                this.group.appendChild(edgeText);
            }

        }

        /*
            Добавляем
        */

        if(this.group){
            this.svg.appendChild(this.group);    
        }
        else{
            this.svg.appendChild(this.poly);
        }
        
    }

    remove = () =>{
        this.poly.remove();
    }

    /*
        Служебные
    */

    placeText(elm, fontSize, x, y, alignH, alignV){

        let width = Math.round(elm.innerHTML.length * fontSize * 0.5);

        let xLeft = 0;
        if(alignH === 'center'){
            xLeft = Math.round(x - width * 0.5);
        }
        else if(alignH === 'end'){
            xLeft = x - width;
        }
        else{
            xLeft = x;
        }

        let yBot = 0;
        if(alignV === 'center'){
            yBot = Math.round(y + fontSize * 0.5);
        }
        else if(alignV === 'end'){
            yBot = y;
        }
        else{
            yBot = y + fontSize;
        }

        setAttributes(elm, {x: xLeft, y: yBot});
    }
}

export default LabeledPolygon;