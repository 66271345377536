/*
    Результат суперзадачи
        supertaskName - название суперзадачи
        totalScore - набранный суммарный балл по всем задачам
        totalTries - сделанное количество попыток сдачи
        totalPassed - кол-во сданных задач
*/
class SupertaskResult {
    constructor(supertaskName){
        this.supertaskName = supertaskName;
        this.totalScore = 0;
        this.totalTries = 0;
        this.totalPassed = 0;
    }

}

export default SupertaskResult;