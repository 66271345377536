function setStyle(elm, style){

    let styleStr = '';

    if(typeof(style) === 'string'){
        styleStr = style;
    }
    else{
        let styleArr = [];
        for(let name in style){
            if(style[name]){
                let printName = name.replace("_", "-");
                styleArr.push(printName + ":" + style[name]);
            }
        }
        styleStr = styleArr.join(';');        
    }

    elm.setAttribute("style",styleStr);

    return styleStr;
}

export default setStyle;