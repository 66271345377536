import React from 'react';

import Button from 'react-bootstrap/Button';

class StatementButtons extends React.Component{

    render(){
        return(
            <div id="statementButtons">
                <Button
                    onClick={this.props.onShowSupertaskStatement}
                >
                    Общее <br/> условие
                </Button>
                <Button
                    onClick={this.props.onShowTaskStatement}
                >
                    Условие <br/> задачи
                </Button>
            </div>
        );
    }
}

export default StatementButtons;