import React from 'react';

/*
    Скрытый ввод для получения нажатий клавиш в элементы, не перехватывающие события onkeydown и onkeypress (в частности объектами сцены).
*/
class HiddenInput extends React.Component{

    constructor(){
        super();
        this.hiddenInputRef = React.createRef();
        this.subscriber = null;
    }

    registerSubscriber = (subscriber) => {
        if(this.subscriber != null && this.subscriber.onFocusOut){
            this.subscriber.onFocusOut();
        }
        this.subscriber = subscriber;
    }

    focus = () =>{  
        this.hiddenInputRef.current.focus();
    }

    onKeyDown = (event) => {   
        if(this.subscriber != null && this.subscriber.onKeyDown){
            this.subscriber.onKeyDown(event);
        }
        return false;
    }    

    onKeyPress = (event) => {
        if(this.subscriber != null && this.subscriber.onKeyPress){
            this.subscriber.onKeyPress(event);
        }
        return false;
    }

    onBlur = () => {
        if(this.subscriber != null && this.subscriber.onBlur){
            this.subscriber.onBlur();
            this.subscriber = null;
        }
    }

    render(){
        return(
            <div id="hiddenInputDiv">
                <input id="hiddenInput" 
                    ref = {this.hiddenInputRef}
                    onKeyDown={this.onKeyDown}
                    onKeyPress={this.onKeyPress}
                    onBlur={this.onBlur}
                 />
            </div>
        );
    }
}

export default HiddenInput;