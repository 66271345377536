import React from 'react';

import setAttributes from '../../../commontools/setAttributes.js';

import TextBox from './scenetools/TextBox.js';
import HiddenInput from './HiddenInput.js';

import okSign from './img/ok_sign.svg';
import failedSign from './img/failed_sign.svg';
import LabeledPolygon from './scenetools/LabeledPolygon.js';
import SquareGrid from './scenetools/grid/SquareGrid.js'
import CoinScales from './scenetools/coin_scales/CoinScales.js';

class Scene extends React.Component{

    constructor(props){
        super(props);

        this.SVGNS = 'http://www.w3.org/2000/svg';

        this.sceneWidth = 640;
        this.sceneHeight = 480;

        this.sceneSVGRef = React.createRef();
        this.hiddenInputRef = React.createRef();
    }

    render(){
        return(
            <div id = "sceneDiv">
                <HiddenInput ref = {this.hiddenInputRef}/>
                <svg id="scene" ref = {this.sceneSVGRef}>
                </svg>
            </div>
        );
    }

    /*
        Прямой доступ к svg
    */

    getSceneSVG = () => {
        return this.sceneSVGRef.current;
    }

    getHiddenInput = () => {
        return this.hiddenInputRef.current;
    }

    /*
        Показ результатов
    */

    // Разрезает message на строки ограниченной длины - не более lengthLimit.
    // При этом удаляет лишнее пробелы
    splitOnLines = (message, lengthLimit) => {
        let words = message.split(' ');

        let lines = [];
        let curLine = [];
        let curLineLen = 0;
        for(let w of words){
            if(w.length > 0){
                if(curLineLen + (curLineLen > 0 ? 1 : 0) + w.length <= lengthLimit){
                    if(curLineLen > 0){
                        curLine.push(' ');
                        curLineLen++;
                    }
                    curLine.push(w);
                    curLineLen += w.length;
                }
                else{
                    if(curLineLen > 0){
                        lines.push(curLine.join(''));
                    }
                    curLine = [];
                    curLine.push(w);
                    curLineLen = w.length;
                }
            }
        }
        if(curLineLen > 0){
            lines.push(curLine.join(''));
        }

        return lines;
    }

    // Создаёт отцентрованный текстовый svg-объект на сцене с данным текстом, 
    // размещённый на указанной строке, строки нумеруются с нуля

    createTextLine(i, text){
        let textObj = document.createElementNS(this.SVGNS, 'text');
        textObj.innerHTML = text;
        let lineHeight = 30;
        setAttributes(textObj, {x : 0, y : Math.ceil(lineHeight * (i+1) * 1.3)});
        setAttributes(textObj, {style : 'font-size: ' + lineHeight + 'px;'});
        this.sceneSVGRef.current.appendChild(textObj);

        let width = Math.round(text.length * lineHeight * 0.5);

        if(textObj.getBBox){
            let bWidth = textObj.getBBox().width;
            if(0 < bWidth && bWidth < this.sceneWidth){
                width = bWidth;
            }
        }

        setAttributes(textObj, {x : Math.floor( (this.sceneWidth - width) / 2 )});

        return textObj;
    }

    // Создаёт массив текстовых объектов для текста message
    createTextLinesArr = (message) => {
        let textLines = [];

        let msg = message.substring(0, 200);

        let lines = this.splitOnLines(msg, 30);

        for(let i in lines){
            textLines.push(this.createTextLine(parseInt(i), lines[i]));
        }

        return textLines;
    }

    showTestResult = (success, message, interval) =>{
        /*
            Показ знака
        */
        let sign = document.createElementNS(this.SVGNS,'image');
        sign.setAttribute('x', 0);
        sign.setAttribute('y', 0);
        sign.setAttribute('width', this.sceneWidth);
        sign.setAttribute('height', this.sceneHeight);        
        if(success){
            sign.setAttribute('href', okSign);
        }
        else{
            sign.setAttribute('href', failedSign);
        }
        this.sceneSVGRef.current.appendChild(sign);

        /*
            Показ сообщения
        */

        if(message !== ''){

            let lines = this.createTextLinesArr(message);

            for(let line of lines){
                window.setTimeout(function(){line.remove()}, interval);
            }
        }

        window.setTimeout(function(){sign.remove()}, interval);
    }    

    /*
        Инструментарий
    */

    clear = () =>{
        this.sceneSVGRef.current.innerHTML = '';
    }

    getTextBox = (props) => {
        return new TextBox(this.sceneSVGRef.current, props, this.hiddenInputRef.current);
    }

    createLabeledPolygon = (props) => {
        return new LabeledPolygon(this.sceneSVGRef.current, props);
    }

    createText = (text, attrs) => {
        let textObj = document.createElementNS(this.SVGNS, 'text');
        setAttributes(textObj, attrs);
        textObj.innerHTML = text;
        this.sceneSVGRef.current.appendChild(textObj);
        return textObj;
    }

    createSquareGrid = (props) => {
        if(this.grid){
            this.grid.removeClickListener();
        }
        
        this.grid = new SquareGrid(
            this.sceneSVGRef.current, this.sceneWidth, this.sceneHeight,
            props.rows,
            props.linestyle,
            props.animDuration,
            props.onCellClick
        );

        this.grid.init();

        return this.grid;
    }

    createCoinScales = () => {
        this.scales = new CoinScales(this, this.sceneWidth, this.sceneHeight);
        return this.scales;
    }
}

export default Scene;