import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class StatementModal extends React.Component{

    constructor(props){
        super(props);

        this.state = 
            {
                contentLoaded : false,
                contentHTML : ''
            };
    }

    // При закрытии окна
    handleClose = () => {
        this.props.onClose();
    }

    // После загрузки содержимого
    onContentLoaded = () => {
        let iFrame = document.getElementById("statementIFrame");
        let iFrameDocument = iFrame.contentDocument || iFrame.contentWindow.document;
        let content = iFrameDocument.body.innerHTML;
        document.getElementById("statementModalBody").innerHTML = content;

        /*this.setState({
            contentLoaded : true,
            contentHTML : content
        })*/
    }

    render(){
        return(
            <Modal
                className="StatementModal" 
                size="xl"
                show={this.props.show} 
                onHide={this.handleClose}>
                
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body id="statementModalBody">
                        <iframe id="statementIFrame"
                            title = "statementIFrame"
                            src={this.props.statementSrc}
                            onLoad={this.onContentLoaded}
                        ></iframe>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }

}


export default StatementModal;