import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TaskSwitcher from '../supertask/TaskSwitcher.js'

import './SupertasksListItem.scss'

class SupertasksListItem extends React.Component{
    /*
        props:
            Обязательные:
                title - название
                logoSrc - URL логотипа
                supertaskResult - результаты суперзадачи

            Если известно общее кол-во задач:
                tasksNum - общее кол-во задач
                taskResults - позадачные результаты
    */

    constructor(props){
        super(props);

        this.statusClass = '';

        if(props.statusClass){
            this.statusClass = props.statusClass;
        }
    }

    render(){
        return(
            <div className='SupertasksListItem'>
                <Row>
                    <Col xs={12} md={4}>
                        <img className="SupertaskLogo"
                            src={this.props.logoSrc} 
                            alt="Нет логотипа"/>
                    </Col>

                    <Col  xs={12} md={6} className={"h-100 " + (this.props.taskResults ? "align-self-center" : "align-self-center")}>
                        <Row>
                            <Col xs={12} className="Title align-text-top">
                        
                                <h3>
                                    {this.props.title}
                                </h3>
                        
                            </Col>
                        </Row>

                        {this.props.taskResults &&
                        <Row>
                            <Col xs={12} className="align-self-end">

                                <TaskSwitcher
                                    sz = "Small"
                                    taskResults = {this.props.taskResults}
                                    curTaskNum = {0}
                                    onTaskClick = {this.props.onTaskClick}
                                />
                            </Col>
                        </Row>}
                    </Col>

                    <Col  xs={12} md={2} className="align-self-center">
                        <div className={"TotalScore" + this.statusClass}>
                            {this.props.supertaskResult.totalScore}
                        </div>
                        <div className={"TotalTries" + this.statusClass}>
                            {this.props.supertaskResult.totalTries}
                        </div>                        
                    </Col>                    

                </Row>

                {/*this.props.taskResults &&
                <Row>
                    <Col xs={12}>
                        <TaskSwitcher
                            taskResults = {this.props.taskResults}
                            curTaskNum = {0}
                            onTaskClick = {()=>{}}
                        />
                    </Col>
                </Row>
                */}
            </div>
        );
    }
}

export default SupertasksListItem;