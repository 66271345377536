import React from 'react';

import StatementButtons from './StatementButtons.js'
import TaskSwitcher from './TaskSwitcher.js'
import SumInf from './SumInf.js'

class InfoAndTaskSwitcherBlock extends React.Component{
    
    render(){

        return(
            <div id="infoAndTaskSwitcherBlock">
                <StatementButtons
                    onShowSupertaskStatement = {this.props.onShowSupertaskStatement}
                    onShowTaskStatement = {this.props.onShowTaskStatement}
                />
                <TaskSwitcher
                            taskResults = {this.props.taskResults}
                            curTaskNum = {this.props.curTaskNum}
                            onTaskClick = {this.props.onTaskClick}
                />
                <SumInf
                    sumInfo = {this.props.sumInfo}
                />
            </div>
        );

    }
}

export default InfoAndTaskSwitcherBlock;
