import TaskResult from "./TaskResult.js";
import SupertaskResult from "./SupertaskResult.js";

class DataHub{

    constructor(props){
        /*
            props:
                username - имя пользователя
        */

        this.username = props.username;
        let localStorage = {};

        if(props && props.clearLocalStorageAll){
            window.localStorage.removeItem("codeStepStorage");
        }
        else{
            let localStorageStr = window.localStorage.getItem("codeStepStorage");
        
            if(localStorageStr != null){
                try{
                    localStorage = JSON.parse(localStorageStr);
                }
                catch(err){
                    localStorage = {};
                    window.localStorage.removeItem("codeStepStorage");
                }
            }    
        }

        if(!localStorage[this.username] || props.clearLocalStorage){
            localStorage[this.username] = {};
        }

        this.localStorage = localStorage;

        if(props.clearLocalStorage){
            this.saveToLocalStorage();
        }
    }

    /*
        Save
    */

    saveToLocalStorage = () => {
        window.localStorage.setItem("codeStepStorage", JSON.stringify(this.localStorage));
    }

    /*
        Workspace
    */

    getWorkspaces = () => {
        if(!this.localStorage[this.username].workspaces){
            this.localStorage[this.username].workspaces = {};
        }

        return this.localStorage[this.username].workspaces;
    }

    getSavedWorkspace = (supertaskName, taskNum) =>{
        
        let workspaces = this.getWorkspaces();
        let res;

        if(!workspaces[supertaskName] || !(res = workspaces[supertaskName][taskNum])){
            res = '';
        }

        return res;
    }

    saveWorkspace = (supertaskName, taskNum, text) =>{
        let workspaces = this.getWorkspaces();

        if(!workspaces[supertaskName]){
            workspaces[supertaskName] = {};
        }
        if(!workspaces[supertaskName][taskNum]){
            workspaces[supertaskName][taskNum] = {};
        }
        workspaces[supertaskName][taskNum] = text;

        this.saveToLocalStorage();
    }

    /*
        TaskResult
    */

    getTaskResults = () =>{
        if(!this.localStorage[this.username].taskResults){
            this.localStorage[this.username].taskResults = {};
        }

        return this.localStorage[this.username].taskResults;        
    }

    getTaskResult = (supertaskName, taskNum) =>{
        let taskResults = this.getTaskResults();

        if(!taskResults[supertaskName]){
            taskResults[supertaskName] = {};
        }
        if(!taskResults[supertaskName][taskNum]){
            taskResults[supertaskName][taskNum] = new TaskResult(taskNum);
        }

        return taskResults[supertaskName][taskNum];
    }

    saveTaskResult = (supertaskName, taskNum, taskResult) => {
        let taskResults = this.getTaskResults();

        if(!taskResults[supertaskName]){
            taskResults[supertaskName] = {};
        }

        taskResults[supertaskName][taskNum] = taskResult;

        this.saveToLocalStorage();
    }

    getSupertaskResult = (supertaskName) => {
        let taskResults = this.getTaskResults();

        let supertaskResult = new SupertaskResult(supertaskName);

        if(taskResults[supertaskName]){
            let totalScore = 0;
            let totalTries = 0;
            let totalPassed = 0;
            for(let taskNum in taskResults[supertaskName]){
                totalScore += taskResults[supertaskName][taskNum].score;
                totalTries += taskResults[supertaskName][taskNum].tries;
                totalPassed += taskResults[supertaskName][taskNum].passed ? 1 : 0;
            }
            supertaskResult.totalScore = totalScore;
            supertaskResult.totalTries = totalTries;
            supertaskResult.totalPassed = totalPassed;
        }

        return supertaskResult;
    }


}

export default DataHub;
