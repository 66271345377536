import React from 'react';

import SceneBlock from './scene/SceneBlock.js'
import Blocklyzone from './Blocklyzone.js'

import TaskExecutor from './TaskExecutor.js'

/*
    Основной элемент управления задачей.
    - выступает связующим звеном между Scene и Blockly zone
    - управляет выполнением кода
    - инициализирует сцену задачи
    - управляет выполнением тестирования задачи
    - фиксирует результат тестирования задачи
*/

class Task extends React.Component{

    constructor(props){
        super(props);

        this.MIN_SIZE_NOT_FIXED_BLOCKLY = 950;

        this.supertask = props.supertask;

        //Объект для результатов тестирования
        this.testingResultInfo = {
            numtest : 0,
            testsNum : 0,
            cntPassed : 0,
            cntFailed : 0,
            score : 0
        }

        this.state = {
            supertaskName : props.taskData.supertaskName,
            taskNum : props.taskData.taskNum,
            taskScriptLoaded : false,
            testingResultInfo : this.testingResultInfo,
            toolboxXML : "",
            isProgramOnPause : false,
            isProgramRunning : false,
            isProgramOnTesting : false,
            speed : 5
        };

        this.getStepInterval = (speed) => {
            switch(parseInt(speed)){
                case 10:
                    return 25;
                case 9:
                    return 50;
                case 8:
                    return 100;
                case 7: 
                    return 200;
                case 6:
                    return 350;
                case 5:
                    return 500;
                case 4:
                    return 750;
                case 3:
                    return 1000;
                case 2:
                    return 1500;
                case 1:
                    return 2000;
                default:
                    return 2000;
            }
        }

        this.calcShowTestSuccessInterval = (stepInterval) => {
            return stepInterval;
        }

        this.calcShowTestFailedInterval = (stepInterval) => {
            return Math.max(stepInterval * 4, 1000);
        }

        this.stepInterval = this.getStepInterval(this.state.speed);

        this.blocklyzoneRef = React.createRef();
        this.sceneBlockRef = React.createRef();

        // Обработчики событий блока сцены
        this.sceneBlockEventHandler = {
            onInitPress : this.onInitPress,
            onManualRunPress : this.onManualRunPress,
            onPausePress : this.onPausePress,
            onNextStepPress : this.onNextStepPress,
            onRunTestingPress : this.onRunTestingPress,
            onStopPress : this.onStopPress,
            onSpeedChange : this.onSpeedChange
        }

        this.taskExecutor = null;
        
    }

    /*
        Жизненный цикл компонента React
    */

    static getDerivedStateFromProps = (props, state)=>{
        let stateChange = {};

        /*
            Если новая задача или суперзадача - требуется обнулить состояние и загрузить новый скрипт
        */
        if(props.taskData.supertaskName !== state.supertaskName
            || props.taskData.taskNum !== state.taskNum){
            stateChange = {
                supertaskName : props.taskData.supertaskName,
                taskNum : props.taskData.taskNum,            
                taskScriptLoaded : false,
                toolboxXML : "",
                isProgramOnPause : false,
                isProgramRunning : false,
                isProgramOnTesting : false
            };        
        }


        return stateChange;
    } 

    componentDidMount(){
        this.loadScript();
    }

    componentDidUpdate(){

        this.setSceneAndWorkspace();

        if(this.state.taskScriptLoaded){
            if(this.taskExecutor == null){
                this.createTaskExecutor();            
            }
        }
        else{
            this.loadScript();
        }
    }

    componentWillUnmount(){
        this.stopExecutionImmediately();
    }

    /*
        Загрузка скрипта и создание объектов
    */

    setSceneAndWorkspace = () => {
        /*
            Сцена и workspace
        */        
       this.scene = this.sceneBlockRef.current.getScene();
       this.workspace = this.blocklyzoneRef.current.getWorkspace();
    }

    loadScript = () => {

        /*
            Если taskExecutor существует - останавливаем тестирование и уничтожаем taskExecutor
        */

        if(this.taskExecutor != null){
            this.taskExecutor.stopExecutionAndTesting();
            this.taskExecutor = null;
        }

        /*
            Загружаем скрипт задачи
        */
        const taskScriptBlock = document.getElementById('taskScriptBlock');
        taskScriptBlock.innerHTML = '';
        const taskScript = document.createElement("script");
        taskScript.type = "text/javascript";
        taskScript.src = this.props.taskData.taskScriptSrc;
        taskScript.async = true;
        taskScript.onload = () => {this.taskScriptLoaded()};

        taskScriptBlock.appendChild(taskScript);
    }

    /*
        Действия после загрузки и выполнения скрипта задачи
    */
    taskScriptLoaded = () => {

        this.task = window.task;

        // Необходимо вызвать здесь, т.к. после обновления состояния будет пересоздан workspace,
        // а toolbox будет использовать эти блоки
        if(this.task.createCustomBlocks){
            this.task.createCustomBlocks();
        }

        // Инициализация информации о полном тестировании
        this.testingResultInfo.testsNum = this.task.testsNum;
        this.testingResultInfo.numtest = 0;
        this.testingResultInfo.cntPassed = 0;
        this.testingResultInfo.cntFailed = 0;
        this.testingResultInfo.score = 0;
        

        let toolboxXML = this.props.supertask.toolboxXML ? this.props.supertask.toolboxXML : '';
        if(this.task.toolboxXML){
            toolboxXML += this.task.toolboxXML;
        }

        this.setState({
            taskScriptLoaded : true,
            toolboxXML : toolboxXML,
            testingResultInfo : this.testingResultInfo,
        });        
    }

    /*
        Handler изменения текста программы
    */
    workspaceDOMChangeHandler = (text) => {
        this.props.onWorkspaceDOMChange(this.props.taskData.taskNum, text);
    }
   
    /*
        Render
    */

    render(){
        return(
            <div className="Task">
                <div id = "taskScriptBlock"></div>
                <SceneBlock ref = {this.sceneBlockRef} 
                    isProgramOnPause = {this.state.isProgramOnPause}
                    isProgramRunning = {this.state.isProgramRunning}
                    isProgramOnTesting = {this.state.isProgramOnTesting}
                    testingResultInfo = {this.state.testingResultInfo}
                    eventHandler = {this.sceneBlockEventHandler}
                    speed = {this.state.speed}/>
                <Blocklyzone ref = {this.blocklyzoneRef}
                    taskScriptLoaded = {this.state.taskScriptLoaded} 
                    toolboxXML = {this.state.toolboxXML}
                    onWorkspaceDOMChange = {this.workspaceDOMChangeHandler}
                />
            </div>
        );
    }

    /*
        Управление выполнением программы
    */

    // Инициализация исходного положения
    onInitPress = () => {
        if(this.taskExecutor != null && !this.state.isProgramRunning){
            this.taskExecutor.manualRunInit();
        }
    }

    // Нажата кнопка ручного выполнения программы
    onManualRunPress = () => {
        if(this.taskExecutor != null && !this.state.isProgramRunning){
            
            // Блокируем workspace для изменений
            this.blocklyzoneRef.current.blockWorkspace();

            this.taskExecutor.manualRun();

            this.setState({
                isProgramRunning : true
            });            
        }
    }

    // Нажата кнопка постановки на паузу и снятия с неё
    onPausePress = () => {
        if(this.taskExecutor != null){
            this.taskExecutor.setProgramOnPause(!this.state.isProgramOnPause);
        }
        // Переводим в положении "На паузе" в любом случае
        this.setState({
            isProgramOnPause : !this.state.isProgramOnPause
        });
    }

    // Нажата кнопка одного шага выполнения
    onNextStepPress = () => {
        if(this.taskExecutor != null && this.state.isProgramRunning && this.state.isProgramOnPause){
            this.taskExecutor.nextStep();
        }
    }

    // Нажата кнопка выполнения полного тестирования
    onRunTestingPress = () => {
        if(this.taskExecutor != null && !this.state.isProgramRunning){
            //Обнуление информации
            this.testingResultInfo.cntPassed = 0;
            this.testingResultInfo.cntFailed = 0;
            this.testingResultInfo.score = 0;
            this.testingResultInfo.numtest = 0;
        
            // Блокируем workspace для изменений
            this.blocklyzoneRef.current.blockWorkspace();

            this.taskExecutor.runTesting();

            this.setState({
                isProgramRunning : true,
                isProgramOnTesting : true,
                testingResultInfo : this.testingResultInfo,
            });

            this.props.onTestingStart(this.props.taskData.taskNum);
        }
    }

    // Нажата кнопка остановки выполнения и тестирования
    onStopPress = () => {
        if(this.taskExecutor != null && this.state.isProgramRunning){
            this.taskExecutor.stopExecutionAndTesting();

            this.setState({
                isProgramRunning : false,
                isProgramOnTesting : false
            });            
        }
    }

    // Изменение скорости выполнения программы
    onSpeedChange = (newSpeed) => {
        this.setState({
            speed : newSpeed
        });
        this.stepInterval = this.getStepInterval(newSpeed);
        if(this.taskExecutor){
            this.taskExecutor.setStepInterval(this.stepInterval);
            this.taskExecutor.setShowTestSuccessInterval(this.calcShowTestSuccessInterval(this.stepInterval));
            this.taskExecutor.setShowTestFailedInterval(this.calcShowTestFailedInterval(this.stepInterval));
        }
    }

    // Немедленно остановить выполнение - будет прекращена всякая анимация и другие задачи
    // Выполняется при перекличении на другую задачу или выхода из работы над задачей
    stopExecutionImmediately = () => {
        if(this.taskExecutor != null){
            // Программа может быть уже остановлена, но анимация будет продолжать выполняться, поэтому делаем останов в любом случае
            this.task.stopImmediately();

            if(this.state.isProgramRunning){
                this.taskExecutor.stopExecutionAndTesting();

                this.setState({
                    isProgramRunning : false,
                    isProgramOnTesting : false
                });                 
            }
        }
    }

    // TODO: добавить функиональность выполнения одного теста

    /*
        Callback TaskExecutor
    */

    // Вывод информации об ошибке
    printError = (message) =>{
        // TODO
        console.log(message);
    }

    // Начало выполнения очередного теста
    handleNextTestStart = (testNum) => {
        this.testingResultInfo.numtest = testNum;
        this.setState({
            testingResultInfo : this.testingResultInfo
        });        
    }

    // Фиксация результатов выполнения теста при полном тестировании
    testFinished = (testNum, success, testScore) => 
    {
        // TODO
        console.log(testNum + ": " + success + ", балл: " + testScore);
        //Изменение результатов для таблицы
        this.testingResultInfo.cntPassed += success;
        this.testingResultInfo.cntFailed += !success;
        this.testingResultInfo.score += testScore;


        this.setState({
            testingResultInfo : this.testingResultInfo
        });
    }

    // Фиксация результатов при полном тестировании
    /*
        currentResult, bestResult:
            totalScore - итоговый результат по задаче
            totalTestsPassed - пройдено тестов
            testsPassed - факт прохождения теста по каждому тесту
            testsScore - балл по каждому тесту
    */
    testingFinished = (currentResult, bestResult) => {
        // TODO
        console.log("Тестирование завершено. Текущий результат: " + 
                currentResult.totalScore + ", лучший результат: " + bestResult.totalScore);

        // Обновляем информацию в таблице статистики
        this.testingResultInfo.score = currentResult.totalScore;
        this.setState({
            testingResultInfo : this.testingResultInfo
        });

        // Фиксируем последний результат
        this.props.onTestingFinish(this.props.taskData.taskNum, currentResult.totalScore, currentResult.totalTestsPassed === this.task.testsNum);
    }

    // Завершение выполнения тестирования - вызывается при завершении ручного выполнения или при заврешении полного тестирования
    runFinished = () =>{
        // Разблокируем workspace
        this.blocklyzoneRef.current.unblockWorkspace();        

        this.setState({
            isProgramRunning : false,
            isProgramOnTesting : false
        });
    }

    /*
        Создание TaskExecutor
    */
    createTaskExecutor = () => {
        try{
            this.scene.clear();

            this.taskExecutor = new TaskExecutor({
                supertask : this.supertask,
                task : this.task,
                scene : this.scene,
                workspace : this.workspace,

                workspaceDomText : this.props.taskData.workspaceDomText,

                stepInterval : this.stepInterval,
                showTestSuccessInterval : this.calcShowTestSuccessInterval(this.stepInterval),
                showTestFailedInterval : this.calcShowTestFailedInterval(this.stepInterval),

                isProgramOnPause : this.state.isProgramOnPause,
                maxSteps : this.task.maxSteps,

                proceedTestingAfterFailed : true,

                callback : {
                    printError : this.printError,
                    onNextTestStart : this.handleNextTestStart,
                    testFinished : this.testFinished,
                    testingFinished : this.testingFinished,
                    runFinished : this.runFinished
                }
            });

            this.taskExecutor.manualRunInit();

        }catch(err){
            /*
                TODO: сделать вывод информации об ошибке на сцену
            */
            console.log("Ошибка инициализации задачи: " + err.message);
        }
    }

}

export default Task;