import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

class HelpModal extends React.Component{

    render(){
        return(
            <Modal
                size="xl"
                show={this.props.show} 
                onHide={this.props.onClose}>
                
                <Modal.Header closeButton>
                    <Modal.Title>Руководство пользователя</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <Accordion>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="0">
                                        Вход в приложение и сохранение данных
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p>
                                        Для входа в приложение необходимо ввести в регистрационной форме имя участника. Имя участника в системе приводится к нормализованному виду:
                                        удаляются лишние пробелы, каждое слово в имени приводится к стандартной форме написания имён собственных: первая буква - заглавная,
                                        остальные - строчные. Пользователи идентифицируются по нормализованному имени, таким образом, например, имена входа "Иван Иванов", 
                                        "иван иванов" и "ИВАН ИВАНОВ" будут ассоциированы с одним и тем же пользователем, а "Иванов Иван" - с другим. 
                                    </p>

                                    <p>
                                        Целью идентификации пользователей является привязка результатов работы к конкретному пользователю и их сохранения после прекращения сеанса работы. 
                                        Результаты работы пользователя сохраняются во внутреннем хранилище браузера в незащищённом виде, поэтому идентификация пользователей не имеет целью 
                                        защиту результатов работы пользователя от несанкционированного доступа и защита учётной записи пользователя паролем не предусмотрена. 
                                        Информация пользователя сохраняется <b>только во внутреннем хранилище браузера</b> и <b>никуда не передаётся.</b> 
                                    </p>

                                    <p>
                                        Вся информация, привязанная ко всем пользователям в данном браузере, может быть удалена путём очистки внутреннего хранилища браузера средствами,
                                        предоставляемыми самим браузером. Если у вас в браузере запрещено использовать постоянное хранилище, система не сможет корректно работать.
                                    </p>

                                    <p>
                                        Закончить сессию работы с системой под своим имененем пользователь может, нажав кнопку "Выйти".
                                    </p>                                    
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="1">
                                        Выбор соревнования
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <p>
                                        После входа в систему пользователю будет предложен список доступных ему соревнований в системе. 
                                        Чтобы пройти в соревнование, пользователю необходимо кликнуть на нём. Соревнование может быть защищено кодовым словом - 
                                        в этом случае пользователю необходимо ввести кодовое слово для данного соревнования, чтобы осуществить вход в него.
                                    </p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>     

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="2">
                                        Выбор задачи
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <p>
                                        В рамках соревнования пользователю может быть предложено решить одну или несколько составных задач, состоящих из нескольких
                                        подзадач (далее просто задач). Задачи, как правило, объединены общей темой и общей частью условия, но могут быть и независимы. 
                                        Чтобы перейти к решению составной задачи, нужно кликнуть на ней в списке составных задач соревнования.
                                    </p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>     

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="3">
                                        Условия задач
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <p>
                                       Чтобы прочитать общую часть условия составной задачи, необходимо нажать на кнопку "Общее условие":
                                    </p>

                                    <img src="img/help/supertask_statement_button.png" alt="Общее условие"/>

                                    <p>
                                        Аналогично, чтобы прочитать условие активной задачи, необходимо нажать на кнопку "Условие задачи":
                                    </p>

                                    <img src="img/help/task_statement_button.png" alt="Условие задачи"/>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>    

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="4">
                                        Переключение между задачами
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <p>
                                       Для переключения между задачами используются кнопки переключения задач:
                                    </p>

                                    <img src="img/help/taskswitch_buttons.png" alt="Кнопки задач"/>

                                    <p>
                                        Кнопки переключения между задачами отображают следующую информацию (сверху вниз):
                                    </p>

                                    <ul>
                                        <li>
                                            Номер задачи
                                        </li>
                                        <li>
                                            Набранный балл по задаче - максимальный балл по всем попыткам полного тестирования задачи
                                        </li>
                                        <li>
                                            Количество попыток тестирования задачи
                                        </li>
                                    </ul>

                                    Кнопки переключения между задачами окрашены в цвет, отражающий статус работы над задачей:

                                    <ul>
                                        <li className="text-task-not-tried">
                                            Не было попыток сдачи - серый цвет
                                        </li>
                                        <li className="text-task-in-progress">
                                            Были попытки сдачи, но не решена полностью - оранжевый цвет
                                        </li>
                                        <li className="text-task-passed">
                                            Задача решена - зелёный цвет
                                        </li>
                                    </ul>

                                    <p>
                                        Номер текущей (активной) задачи выделен белым цветом.
                                    </p>

                                    <p>
                                        Справа от кнопок переключения задач расположен элемент, отображающий информацию о сумме баллов и суммарному количеству попыток
                                        тестирования по составной задаче:
                                    </p>

                                    <img src="img/help/supertasksum.png" alt="Сумма по задаче"/>

                                    <p>
                                        Цветовое выделение данного элемента соответствует тем же правилам, применённым к статусу работы над составной задачей.
                                    </p>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>    
                        
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="5">
                                        Выполнение программы и полное тестирование
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <p>
                                       Меню управления выполнением программы и тестированием состоит из следующих кнопок:
                                    </p>

                                    <img src="img/help/task_control_buttons.png" alt="Меню управления выполнением и тестированием"/>
                                    
                                    <p>
                                    Слева направо:
                                    </p>
                                    
                                    <ul>
                                        <li>
                                            <b>Инициализация</b>: привести сцену в исходное стостояние для текущей подзадачи. Кнопка неактивна в режиме выполнения программы.
                                        </li>
                                        <li>
                                            <b>Выполнить</b>: выполнить программу пользователя с текущего исходного положения.
                                            Выполнение программы в данном режиме не измененяет количество попыток тестирования.
                                            Результат выполнения программы проверяется так же, как и все тесты при полном тестировании, 
                                            но не влияет на набранный балл по задаче.
                                            Кнопка неактивна в режиме полного тестирования.
                                        </li>
                                        <li>
                                            <b>Пауза</b>: поставить выполнение программы на паузу. Кнопка может находиться в одном из двух положений: нажата или отжата.
                                            Положение кнопки может быть изменено во всех режимах работы.
                                        </li>
                                        <li>
                                            <b>Следующая инструкция</b>: выполнить следующюю инструкцию программы. По нажатию кнопки можно выполнить очередную инструкцию программы.
                                            Выполняющийся блок подсвечен. Кнопка активна только при нажатой кнопке "Пауза" и в режиме выполнения программы или полного 
                                            тестирования.
                                        </li>  
                                        <li>
                                            <b>Выполнить тестирование</b>: провести полное тестирование программы. Нажатие на кнопку увеличивает количество попыток тестирования по
                                            текущей подзадаче. В результате нажатия запускается полное тестирование подзадачи на множестве тестов. Кнопка не активна
                                            в режиме обычного выполнения задачи.
                                        </li>                                   
                                        <li>
                                            <b>Стоп</b>: остановить выполнение и полное тестирование задачи. Кнопка не активна, если не выполняется ни то, ни другое.
                                        </li>                                   
                                    </ul>

                                    <p>
                                        В ходе полного тестирования и по его завершению доступен протокол полного тестирования, в котором указано общее количество тестов, 
                                        номер текущего теста, количество успешно пройденных тестов, количество проваленных тестов и набранный балл по задаче:
                                    </p>

                                    <img src="img/help/stats_panel.png" alt="Меню управления выполнением и тестированием"/>

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>     

                        <Card>
                            <Card.Header>
                                <Accordion.Toggle className="HelpTopicHeader" as={Card.Header} variant="link" eventKey="6">
                                        Сохранение информации пользователя
                                </Accordion.Toggle>
                            </Card.Header>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    
                                    Для каждого пользователя в хранилище браузера для каждой подзадачи, над которой он работал, сохраняется информация об успешности
                                    сдачи подзадачи, набранном балле и количестве попыток сдачи. Также по каждой подзадаче сохраняется текущее содержание рабочего пространства
                                    блоков. 

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>                                                                                                                                 

                    </Accordion>                    

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }    
}

export default HelpModal;