import CodeExecutor from './CodeExecutor.js'
import TaskExecutorInterface from './TaskExecutorInterface.js'

function TaskExecutor(props){

    /*
        Задаваемые параметры:

        props:
            task - объект задачи
            scene - объект сцены
            workspace - объект Blockly workspace

            workspaceDomText - сохранённый текст программы, если '' - значит пусто
            
            stepInterval - интервал в мс на шаг алгоритма
            showTestSuccessInterval - интервал в мс показа информации об успешности завершения теста
            showTestFailedInterval - интервал в мс показа информации о неуспешности завершения теста

            isProgramOnPause - программа на паузе
            maxSteps - максимальное количество шагов в алгоритме

            proceedTestingAfterFailed - продолжать ли тестирования после непройденного теста

            callback - передача информации вызывающему объекту
                printError() - вывод информации об ошибке
                onNextTestStart() - при старте очередного теста при полном тестировании
                testFinished(testNum, success, testScore) - фиксация результата выполнения теста 
                                                            при завершении выполнение теста (не выхывается при ручном
                                                            и одиночном выполнении теста)
                testingFinished(currentResult, bestResult) - фиксация результата выполнения тестириования 
                                                            при завершении тестирования
                runFinished() - завершение выполнения тестирования во всех режимах

    */

    this.supertask = props.supertask;
    this.task = props.task;
    this.scene = props.scene;
    this.workspace = props.workspace;

    this.workspaceDomText = props.workspaceDomText;

    this.stepInterval = props.stepInterval;
    this.showTestSuccessInterval = props.showTestSuccessInterval;
    this.showTestFailedInterval = props.showTestFailedInterval;

    this.isProgramOnPause = props.isProgramOnPause;
    this.maxSteps = props.maxSteps;

    this.proceedTestingAfterFailed = props.proceedTestingAfterFailed;

    this.callback = props.callback;

    /*
        Константа
    */

    // Дополнительный интервал в мс для запуска следующего теста - добавляет к интервалу показа сообщения о результате перед запуском следующего теста
    this.ADDITION_WAIT_INTERVAL = 10;

    // Интервал ожидания снятия программы с паузы при старте очередного теста
    this.WAIT_FOR_PAUSE_OFF_INTERVAL = 10;    

    /*
        Сообщения
    */

    this.msgs = {
        onManualStop : 'Ручное прекращение работы программы'
    }
    /*
        Внутренние переменные
    */

    // Номер текущего теста
    this.currentTestNum = 0;

    // Выполняется тестирование
    this.doTesting = false;

    // Выполняется только один тест
    this.singleTestExecution = false;

    //Результаты тестирования:

    // Лучший
    this.bestResult = {
        totalScore : 0,
        totalTestsPassed : 0,
        testsPassed : new Array(this.task.testsNum).fill(false),
        testsScore : new Array(this.task.testsNum).fill(0)
    };

    // Текущий
    this.currentResult = {
        totalScore : 0,
        totalTestsPassed : 0,
        testsPassed : new Array(this.task.testsNum).fill(false),
        testsScore : new Array(this.task.testsNum).fill(0)
    };   

    /*
        Интерфейс TaskExecutorInterface
    */
   
    // Поставить выполнение программы на паузу для завершения выполнения действия
    this.waitForActionComplete = () => {
        this.codeExecutor.waitForActionComplete();
    }

    // Действие завершено
    this.actionComplete = () => {
        this.codeExecutor.actionComplete();
    }

    // Аварийное завершение работы программы
    this.failExecution = (errorText) => {
        // Метод this.testExecComplete будет вызван в результате выполнения this.codeExecutor.stopProgramExecution
        this.codeExecutor.stopProgramExecution(false, errorText);
    }
        
    /*
        Инициализация задачи
    */

    this.task.init(this.supertask, this.scene, new TaskExecutorInterface(this));

    // Размещение начальных блоков на workspace

    let blocklyXML = '';

    if(this.workspaceDomText.length > 0){
        blocklyXML = this.workspaceDomText;
    }
    else{
        if(this.supertask.initialBlocklyWorkspace){
            blocklyXML = blocklyXML + this.supertask.initialBlocklyWorkspace;
        }
    
        if(this.task.initialBlocklyWorkspace){
            blocklyXML = blocklyXML + this.task.initialBlocklyWorkspace;
        }    

        if(blocklyXML.length > 0){
            blocklyXML = '<xml>' + blocklyXML + '</xml>';
        }
    }

    if(blocklyXML.length > 0){
        window.Blockly.Xml.domToWorkspace(window.Blockly.Xml.textToDom(blocklyXML), this.workspace);
    }

    // Включение деактивации неприсоединённых блоков
    if(this.supertask.disableOrphans || this.task.disableOrphans){
        this.workspace.addChangeListener(window.Blockly.Events.disableOrphans);
    }
    

    // Задание stepInterval

    this.task.setStepInterval(this.stepInterval);

    /*
        Исполнитель кода
    */

    this.codeExecutor = new CodeExecutor(this.workspace, this.callback.printError);

    /*
    ************************************************
        Выполнение программы
    ************************************************
    */

    /*
        Интерфейсные методы
    */

    // Инициализация для ручного выполнения
    this.manualRunInit = () => {
        this.task.testInit(0);
    }

    // Ручное выполнение 
    this.manualRun = () => {
        this.currentTestNum = 0;
        this.task.startManualRun();
        this.run();
    }

    // Выполнение очередного шага при выполнении программы по шагам
    this.nextStep = () => {
        if(this.codeExecutor.isProgramRunning){
            this.codeExecutor.manualNextStep();
        }
        else{
            this.nextStepHandled = true;
        }
    }

    // Выполнение одного теста
    this.executeSingleTest = (testNum) => {
        this.singleTestExecution = true;
        this.runTest(testNum);
    }

    // Выполнение тестирования
    this.runTesting = () => {
        this.currentResult = {
            totalScore : 0,
            totalTestsPassed : 0,
            testsPassed : new Array(this.task.testsNum).fill(false),
            testsScore : new Array(this.task.testsNum).fill(0)
        };
        this.doTesting = true;
        this.runTest(1);
    }

    // Остановить выполнение программы и тестирование
    this.stopExecutionAndTesting = () => {

        // Если запущен таймер начала следующего теста - очищаем его
        if(this.timerId){
            window.clearTimeout(this.timerId);
        }

        // Если запущен таймер инициализации следующего теста
        if(this.nextTestRunTimerId){
            window.clearTimeout(this.nextTestRunTimerId);
        }

        if(this.codeExecutor.isProgramRunning){
            // Если выполняется программа - завершаем её, установив флаг отсутствия тестирования 
            // - она самостоятельно завершит тестирование
            this.doTesting = false;
            this.codeExecutor.stopProgramExecution(false, this.msgs.onManualStop);
        }
        else if(this.doTesting){
            // Иначе, если выполняется тестирование, но не запущена программа, значит мы
            // находимся между окончанием работы одного теста и началом работы другого -
            // завершаем тестиирование отсюда
            this.doTesting = false;
            this.testingFinished();
        }

        // Очищаем служебные флаги
        this.clearFlags();

    }

    // Включение и отключение паузы выполнения
    this.setProgramOnPause = (isProgramOnPause) => {
        this.isProgramOnPause = isProgramOnPause;
        this.codeExecutor.setProgramOnPause(isProgramOnPause);
    }

    // Задание интервала на шаг
    this.setStepInterval = (stepInterval) => {
        this.stepInterval = stepInterval;
        this.codeExecutor.setStepInterval(stepInterval);
        this.task.setStepInterval(stepInterval);
    }

    /*
        Внутренние методы
    */

    // Выполнить программу после инициализации
    this.run = () => {
        let code = window.Blockly.JavaScript.workspaceToCode(this.workspace);    
        this.codeExecutor.execute(code,
            {
                stepInterval : this.stepInterval,
                maxSteps : this.maxSteps,
                isProgramOnPause : this.isProgramOnPause,
                makeWrappers : this.task.makeWrappers,
                onExecComplete : this.testExecComplete
            });        
    }

    // Выполнение очередного теста при полном тестировании или тестировании на одном тесте
    // не вызывается при ручном выполнении программы
    this.runTest = (testNum) => { 
        if(this.isProgramOnPause && testNum > 1 && !this.nextStepHandled){
            // Пауза перед инициализацией очередного теста
            this.nextTestRunTimerId = window.setTimeout(() => {this.runTest(testNum);}, this.WAIT_FOR_PAUSE_OFF_INTERVAL);
        }
        else{
            this.callback.onNextTestStart(testNum);
            this.nextStepHandled = false;
            this.currentTestNum = testNum;
            this.task.testInit(testNum);
            this.run();
        }
    }    

    /*
    *****************************************************
        Проверка результата выполнения программы
    *****************************************************
    */

    // Завершение выполнения программы на тесте
    this.testExecComplete = (success, message) => {
        let waitInterval = 0;
        let passed = success;
        if(passed){
            let testResult = this.task.getTestResult();
            passed = testResult.passed;
            if(passed){
                waitInterval = this.showTestSuccessInterval;
                this.scene.showTestResult(passed, '', this.showTestSuccessInterval);
            }
            else{
                waitInterval = this.showTestFailedInterval;
                this.scene.showTestResult(passed, testResult.message, this.showTestFailedInterval);
                if(testResult.message && testResult.message != null && testResult.message !== ''){
                    this.callback.printError(testResult.message);
                }
            }
        }
        else{
            waitInterval = this.showTestFailedInterval;
            this.scene.showTestResult(false, message, this.showTestFailedInterval);
            if(message !== ''){
                this.callback.printError(message);
            }
        }

        if(! this.singleTestExecution){
            if(this.currentTestNum > 0){
                // Баллы начисляются только при полном тестировании
                // Если это полное тестирование - возвращаем результаты теста
                this.testFinished(passed);
                if(this.currentTestNum < this.task.testsNum && this.doTesting){
                    // Если это не последний тест и тестирование не прервано
                    if(passed || this.proceedTestingAfterFailed){
                        this.timerId = window.setTimeout(() => {this.runTest(this.currentTestNum + 1)}, waitInterval + this.ADDITION_WAIT_INTERVAL);
                    }
                    else{
                        // Если тест не пройден и выставлен флаг не продолжать тестирование при не пройденном тесте -
                        // завершаем тестирование и возвращаем результаты тестирования
                        this.testingFinished();
                    }
                }
                else{
                    // Если это был последний тест или тестирование прервано - завершаем тестирование и возвращаем результаты тестирования
                    this.testingFinished();
                }
            }
            else{
                // Если это был ручной запуск выполнения
                // Завершаем выполнение всех тестов
                this.runFinished();
            }
        }
        else{
            // Если это было одиночное выполнение теста
            // Сбрасываем флаг выполнения одного теста
            this.singleTestExecution = false;
            // Завершаем выполнение всех тестов
            this.runFinished();
        }

    }

    // Действие по завершении выполнения одного теста
    // (вызывается, только если это не ручное выполнение программы и не запуск одного теста)
    this.testFinished = (passed) => {
        let testScore = 0;
        if(passed){
            if(this.task.testScore){
                testScore += this.task.testScore;
            }
            else{
                testScore += 1;
            }
        }
        this.currentResult.testsPassed[this.currentTestNum - 1] = passed;
        this.currentResult.testsScore[this.currentTestNum - 1] = testScore;
        this.currentResult.totalScore += testScore;
        if(passed){
            this.currentResult.totalTestsPassed++;
        }

        this.callback.testFinished(this.currentTestNum, passed, testScore);
    }

    // Завершение тестирования
    this.testingFinished = () => {
        this.doTesting = false;

        if(this.task.fullSolutionScore && this.currentResult.totalTestsPassed === this.task.testsNum){
            this.currentResult.totalScore = this.task.fullSolutionScore;
        } 
        if(this.currentResult.totalScore > this.bestResult.totalScore){
            this.bestResult.totalScore = this.currentResult.totalScore;
            this.bestResult.totalTestsPassed = this.currentResult.totalTestsPassed;
            this.bestResult.testsPassed = this.currentResult.testsPassed.slice();
            this.bestResult.testsScore = this.currentResult.testsScore.slice();
        }

        this.callback.testingFinished(this.currentResult, this.bestResult);

        // Завершаем выполнение всех тестов
        this.runFinished();
    }

    // Очищаем служебные флаги
    this.clearFlags = () => {
        this.nextStepHandled = false;
    }
    

    // Завершаем выполнение всех тестов
    // Вызов завершает выполнение всех тестов во всех режимах тестирования
    this.runFinished = () => {
        // Очищаем служебные флаги
        this.clearFlags();

        this.callback.runFinished();
    }

    /*
    *********************************************************************
        Задание специальных параметров
    *********************************************************************
    */

    this.setShowTestSuccessInterval = (showTestSuccessInterval) =>{
        this.showTestSuccessInterval = showTestSuccessInterval;
    }
    
    this.setShowTestFailedInterval = (showTestFailedInterval) =>{
        this.showTestFailedInterval = showTestFailedInterval;
    }

    this.setProceedTestingAfterFailed = (proceedTestingAfterFailed) =>{
        this.proceedTestingAfterFailed = proceedTestingAfterFailed;
    }


}

export default TaskExecutor;