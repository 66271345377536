import React from 'react';

class SceneStatsPanel extends React.Component{

    render(){
        return(
            <div className="SceneStatsPanel">
                <table>
                    <thead>
                        <tr>
                            <th>Всего тестов</th>
                            <th>Номер теста</th>
                            <th>Пройдено</th>
                            <th>Провалено</th>
                            <th>Балл</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {/*Измененены значения*/}
                            <td> {this.props.testingResultInfo.testsNum} </td>
                            <td> {this.props.testingResultInfo.numtest} </td>
                            <td> {this.props.testingResultInfo.cntPassed} </td>
                            <td> {this.props.testingResultInfo.cntFailed} </td>
                            <td> {this.props.testingResultInfo.score} </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default SceneStatsPanel;