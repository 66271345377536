import {setAttrs} from '../../../../../commontools/setAttributes.js';

/*
    All durations in ms
*/

function Sprite(grid, elem, size, col, row, direction){
    this.grid = grid;
    this.elem = elem;
    this.height = size;
    this.width = size;
    this.startCol = col;
    this.startRow = row;
    this.col = col;
    this.row = row;
    // direction in {0, 1 , 2, 3}:
    // 0 - вправо
    // 1 - вверх
    // 2 - влево
    // 3 - вниз
    this.direction = direction;

    this.angle = 0;

    // Сдвиг окна по столбцам от момента исходного размещения
    this.windowColShift = 0;
    // Сдвиг окна по строкам от момента исходного размещения
    this.windowRowShift = 0;


    let x = this.grid.leftColumnXleft + (col - this.grid.leftColumn) * this.grid.h + Math.round((this.grid.h - this.width)/2);
    let y = this.grid.bottomRowYBottom - (row - this.grid.bottomRow + 1) * this.grid.h + Math.round((this.grid.h - this.height)/2);
    
    setAttrs(this.elem, {
        x : x,
        y : y
    });

    /*
        Возможность выбора
    */

    this.selectable = true;
    
    /*
        Движения
    */

    this.moveTo = (col, row, duration, onComplete) => {
        this.col = col;
        this.row = row;

        let xShift = (this.col - this.startCol - this.windowColShift) * this.grid.h;
        let yShift = -(this.row - this.startRow - this.windowRowShift) * this.grid.h;

        return window.gsap.to(this.elem, {
                x : xShift,
                y : yShift,
                duration : duration / 1000,
                onComplete : onComplete
            });
        
    }

    this.rotate = (angle, duration, onComplete) => {

        this.angle -= angle;
        this.direction = (this.direction + (angle / 90) % 4 + 4) % 4;

        return window.gsap.to(this.elem, {
                rotation : this.angle,
                transformOrigin:"50% 50%",
                duration : duration / 1000,
                onComplete : onComplete
            });

    }

    this.rotateLeft = (duration, onComplete) => {
        return this.rotate(90, duration, onComplete);
    }

    this.rotateRight = (duration, onComplete) => {
        return this.rotate(-90, duration, onComplete);
    }

    this.rotateBack = (duration, onComplete) => {
        return this.rotate(180, duration, onComplete);
    }    

    this.stepForward = (duration, onComplete) => {
        let col = this.col;
        let row = this.row;
        if(this.direction === 0){
            col += 1;
        }
        else if(this.direction === 1){
            row += 1;
        }
        else if(this.direction === 2){
            col -= 1;
        }
        else{
            row -= 1;
        }
        
        return this.moveTo(col, row, duration, onComplete);
    }

    this.stepEast = (duration, onComplete) => {
        return this.moveTo(this.col + 1, this.row, duration, onComplete);
    }

    this.stepNorth = (duration, onComplete) => {
        return this.moveTo(this.col, this.row + 1, duration, onComplete);
    }

    this.stepWest = (duration, onComplete) => {
        return this.moveTo(this.col - 1, this.row, duration, onComplete);
    }   
    
    this.stepSouth = (duration, onComplete) => {
        return this.moveTo(this.col, this.row - 1, duration, onComplete);
    }

    /*
        Проверка наличия стены
    */
    
    this.hasWallOnEast = () => {
        return this.grid.hasWallOnEast(this.col, this.row);
    }

    this.hasWallOnWest = () => {
        return this.grid.hasWallOnWest(this.col, this.row);
    }

    this.hasWallOnSouth = () => {
        return this.grid.hasWallOnSouth(this.col, this.row);
    }    

    this.hasWallOnNorth = () => {
        return this.grid.hasWallOnNorth(this.col, this.row);
    }

    this.hasWallOnDirection = (direction) => {
        if(direction === 0){
            return this.hasWallOnEast();
        }
        else if(direction === 1){
            return this.hasWallOnNorth();
        }
        else if(direction === 2){
            return this.hasWallOnWest();
        }
        else {
            return this.hasWallOnSouth();
        }
    }

    this.hasWallOnFront = () => {
        return this.hasWallOnDirection(this.direction);
    }

    this.hasWallOnLeft = () => {
        return this.hasWallOnDirection((this.direction + 1)%4);
    }

    this.hasWallOnRight = () => {
        return this.hasWallOnDirection((this.direction + 3)%4);
    }

    this.hasWallOnBack = () => {
        return this.hasWallOnDirection((this.direction + 2)%4);
    }    

}

export default Sprite;