import setAttrs from 'commontools/setAttributes';
import setStyle from '../setStyle';


import ScalesBalance from './img/scales-balance.svg';
import ScalesLeftOverweight from './img/scales-left-overweight.svg';
import ScalesRightOverweight from './img/scales-right-overweight.svg';
import CoinGenuine from './img/coin-genuine.svg';
import CoinCounterfeit from './img/coin-counterfeit.svg';

const SVGNS = "http://www.w3.org/2000/svg";
const XLINKNS = "http://www.w3.org/1999/xlink";

/*
    Инструментарий для задач про взвешивание монет
    Нумерация монет для внешнего API - с 1
    Нумерация монет во внутренних функциях - с 0
*/
export default function CoinScales(scene, sceneWidth, sceneHeight){

    this.scene = scene;

    const scalesX = Math.floor(sceneWidth * 0.175);
    const scalesY = Math.floor(sceneHeight * 0.23);
    const scalesWidth = Math.floor(sceneWidth * 0.65);
    const scalesHeight = scalesWidth;

    this.scalesPlace = document.createElementNS(SVGNS, "g");
    this.scalesBalance = document.createElementNS(SVGNS, "image");
    this.scalesLeftOverweight = document.createElementNS(SVGNS, "image");
    this.scalesRightOverweight = document.createElementNS(SVGNS, "image");
    let scalesAttrs = {
        x : scalesX,
        y : scalesY,
        width : scalesWidth,
        height : scalesHeight
    };
    setAttrs(this.scalesBalance, scalesAttrs);
    setAttrs(this.scalesLeftOverweight, scalesAttrs);
    setAttrs(this.scalesRightOverweight, scalesAttrs);
    this.scalesBalance.setAttributeNS(XLINKNS, "href", ScalesBalance);
    this.scalesLeftOverweight.setAttributeNS(XLINKNS, "href", ScalesLeftOverweight);
    this.scalesRightOverweight.setAttributeNS(XLINKNS, "href", ScalesRightOverweight);
    this.scalesBalance.style.visibility = "hidden";
    this.scalesLeftOverweight.style.visibility = "hidden";
    this.scalesRightOverweight.style.visibility = "hidden";
    this.scalesPlace.appendChild(this.scalesBalance);
    this.scalesPlace.appendChild(this.scalesLeftOverweight);
    this.scalesPlace.appendChild(this.scalesRightOverweight);

    this.scene.getSceneSVG().appendChild(this.scalesPlace);


    // Инициализация
    this.lastLeftBowlWeight = 0;
    this.lastRightBowlWeight = 0;
    this.init = () => {
        this.scalesBalance.style.visibility = "visible";
        this.scalesLeftOverweight.style.visibility = "hidden";
        this.scalesRightOverweight.style.visibility = "hidden";
        this.leftBowlWeight = 0;
        this.rightBowlWeight = 0;
    }

    // Изменение положения весов
    this.setBowlsWeighed = () => {
        this.scalesBalance.style.visibility = "hidden";
        this.scalesLeftOverweight.style.visibility = "hidden";
        this.scalesRightOverweight.style.visibility = "hidden";

        if(this.lastLeftBowlWeight > this.lastRightBowlWeight){
            this.scalesLeftOverweight.style.visibility = "visible";
        }
        else if(this.lastLeftBowlWeight < this.lastRightBowlWeight){
            this.scalesRightOverweight.style.visibility = "visible";
        }
        else{
            this.scalesBalance.style.visibility = "visible";
        }

    }

    this.setBowlsBalance = () => {
        this.scalesBalance.style.visibility = "visible";
        this.scalesLeftOverweight.style.visibility = "hidden";
        this.scalesRightOverweight.style.visibility = "hidden";        
    }

    this.coins = [];
    this.coinsImg = [];
    this.coinSize = Math.round(sceneHeight / 24);
    // Создать и нарисовать монеты:
    // coins: [{
    //    weight : int,
    //    genuine : boolean
    //}]
    this.createCoins = (coins) => {
        this.coins = coins;
        if(coins.length <= 3){
            this.coinSize = Math.round(sceneHeight / 12);
        }
        else if(coins.length <= 6){
            this.coinSize = Math.round(sceneHeight / 18);
        }
        else {
            this.coinSize = Math.round(sceneHeight / 24);
        }

        this.coinsImg = [];
        for(let i in coins){
            let img = document.createElementNS(SVGNS, "image");
            let group = document.createElementNS(SVGNS, "g");
            let number = document.createElementNS(SVGNS, "text");
            group.appendChild(img);
            group.appendChild(number);

            let pos = this.coinHeapPosition(parseInt(i));
            setAttrs(img, {
                x : pos.x,
                y : pos.y,
                width : this.coinSize,
                height : this.coinSize
            });
            if (coins[i].genuine){
                img.setAttributeNS(XLINKNS, "href", CoinGenuine);
            }
            else{
                img.setAttributeNS(XLINKNS, "href", CoinCounterfeit);
            }

            number.innerHTML = (parseInt(i)+1).toString();
            setAttrs(number, {
                x : pos.x + Math.round( this.coinSize * (0.37 - 0.15 * (parseInt(i) + 1 >= 10 ? 1 : 0)) ),
                y : pos.y + Math.round(this.coinSize * 0.62)
            });
            setStyle(number, "font-size: " + Math.round(this.coinSize * 0.5) + "px; color: black;");
            
            this.coinsImg.push(group);
            this.scene.getSceneSVG().appendChild(group);
        }
    }

    this.removeAllCoins = () => {
        for(let c of this.coinsImg){
            c.remove();
        }
        this.coins = [];
        this.coinsImg = [];
    }

    // Вычислить положение монеты с номером n (нумерация с 0)
    // в общей куче
    this.coinHeapPosition = (n) => {
        return {
            x : Math.round( this.coinSize * 1.1 * (n % (Math.floor(sceneWidth / this.coinSize) - 3) ) + Math.round(sceneWidth * 0.02) ),
            y : Math.floor(n / (Math.floor(sceneWidth / this.coinSize) - 3) ) * this.coinSize + Math.round(sceneHeight * 0.05)
        }
    }

    /*
    ***************************
    ** Удаление монет        **
    ***************************
    */

    // Удалить монеты с номерами 
    // coinsNum
    // Нумерация с 1
    // duration - продолжительность анимации в милисекундах
    // callback - действие по завершении
    this.removeCoins = (coinsNum, duration, callback) => {
        // Сортируем и оставляем уникальные номера монет, переходим к нумерации с 0
        let removingCoinsNum = coinsNum.slice().sort( (a,b) => (a-b) ).filter( (v, i, arr) => (i === 0 || arr[i-1] < v) ).map( (v) => (v-1));
        let coins = this.coins.slice().filter((v, i) => (removingCoinsNum.find((x) => (x === i)) === undefined));

        // Осуществляем анимацию
        let globalTimeline = window.gsap.timeline({onComplete: callback});
        let removeCoinsTimeline = window.gsap.timeline({onComplete: () => {
            this.removeAllCoins();
            this.createCoins(coins);
        }});
        let pauseTimeline = window.gsap.timeline();

        for(let i of removingCoinsNum){
            removeCoinsTimeline.to(this.coinsImg[i], {y: - Math.round(sceneHeight * 0.25), duration: Math.round(duration/2000)}, 0);
        }
        pauseTimeline.addPause("+=" + duration / 2000);

        globalTimeline.add(removeCoinsTimeline);
        globalTimeline.add(pauseTimeline);
    }    

    /*
    ***************************
    ** Взвешивание           **
    ***************************
    */

    // Вычислить положение монеты
    // на чаше bowlNum - 0 - левая чаша, 1 - правая чаша
    // bowlPosition: 0 - в положении баланса, 1 - нижнее положение чаши, -1 - верхнее
    // если монета является n-ой монетой на данной чаше
    // (нумерация монет на чаше - с 0)
    this.coinBowlPosition = (bowlNum, bowlPosition, n) => {
        let bowlWidth = Math.round(sceneWidth * 0.2);
        let leftBowlLeftBottom = {
            x : Math.round(bowlWidth * 1.1),
            y : Math.round(sceneHeight * 0.815) - this.coinSize
        }
        let lowRowCoinsNum = Math.floor(bowlWidth * 0.8 / this.coinSize);
        let row = 0;
        let numInRow = n;
        let rowNum = lowRowCoinsNum;
        let overFlowRows = 0;
        while(numInRow >= rowNum){
            row++;
            numInRow -= rowNum;
            rowNum--;
            if(rowNum < 1){
                rowNum = 1;
                overFlowRows++;
            }
        }
        return {
            x : leftBowlLeftBottom.x + numInRow * this.coinSize + Math.round((row - overFlowRows) * this.coinSize / 2) + bowlNum * bowlWidth * 2,
            y : leftBowlLeftBottom.y - row * this.coinSize + Math.round(bowlPosition * bowlWidth * 0.3)
        }
    }

    // Вектор между точками
    this.vector = (point1, point2) => {
        return {
            x: point2.x - point1.x,
            y: point2.y - point1.y
        }
    }

    // Взвесить 2 группы монет
    // leftBowlNums, rightBowlNums - массивы номеров монет на левой и правой чаше (нумерация с 1)
    // множества номеров не должны пересекаться
    // duration - продолжительность анимации в милисекундах
    // callback - действие по завершении
    this.weighCoins = (leftBowlNums, rightBowlNums, duration, callback) => {
        let moveCoinsOnBowlsDuration = (duration / 1000) / 4;
        let beforeWeighingPauseDuration = (duration / 1000) / 4;
        let afterWeighingPauseDuration = (duration / 1000) / 4;
        let moveCoinsBackDuration = (duration / 1000) / 4;
        this.lastLeftBowlWeight = 0;
        this.lastRightBowlWeight = 0;

        // Сортируем и оставляем уникальные номера монет, переходим к нумерации с 0
        let left = leftBowlNums.slice().sort( (a,b) => (a-b) ).filter( (v, i, arr) => (i === 0 || arr[i-1] < v) ).map( (v) => (v-1));
        let right = rightBowlNums.slice().sort( (a,b) => (a-b) ).filter( (v, i, arr) => (i === 0 || arr[i-1] < v) ).map( (v) => (v-1));

        // Вычисляем веса
        for(let i of left){
            this.lastLeftBowlWeight += this.coins[i].weight;
        }
        for(let i of right){
            this.lastRightBowlWeight += this.coins[i].weight;
        }

        // Осуществляем анимацию
        let globalTimeline = window.gsap.timeline({onComplete: callback});

        let moveCoinsOnBowls = window.gsap.timeline();
        let moveBowls = window.gsap.timeline({onComplete : this.setBowlsWeighed});
        let moveCoinsBack = window.gsap.timeline({onComplete : this.setBowlsBalance});

        // Кладем монеты на чаши
        for(let i in left){
            let vec = this.vector(this.coinHeapPosition(left[i]), this.coinBowlPosition(0, 0, parseInt(i)));
            moveCoinsOnBowls.to(this.coinsImg[left[i]], {x: vec.x, y: vec.y, duration: moveCoinsOnBowlsDuration}, 0);
        }
        for(let i in right){
            let vec = this.vector(this.coinHeapPosition(right[i]), this.coinBowlPosition(1, 0, parseInt(i)));
            moveCoinsOnBowls.to(this.coinsImg[right[i]], {x: vec.x, y: vec.y, duration: moveCoinsOnBowlsDuration}, 0);
        }

        // Перемещаем монеты в соответствии с движением чаш
        let leftBowlPosition =   this.lastLeftBowlWeight < this.lastRightBowlWeight ? -1   
                                :this.lastLeftBowlWeight > this.lastRightBowlWeight ? 1
                                :0;
        for(let i in left){
            let vec = this.vector(this.coinHeapPosition(left[i]), this.coinBowlPosition(0, leftBowlPosition, parseInt(i)));
            moveBowls.to(this.coinsImg[left[i]], {x: vec.x, y: vec.y, duration: 0.001}, 0);
        }
        for(let i in right){
            let vec = this.vector(this.coinHeapPosition(right[i]), this.coinBowlPosition(1, -leftBowlPosition, parseInt(i)));
            moveBowls.to(this.coinsImg[right[i]], {x: vec.x, y: vec.y, duration: 0.001}, 0);
        }

        // Убираем монеты с чаш
        for(let i in left){
            moveCoinsBack.to(this.coinsImg[left[i]], {x: 0, y: 0, duration: moveCoinsBackDuration}, 0);
        }
        for(let i in right){
            moveCoinsBack.to(this.coinsImg[right[i]], {x: 0, y: 0, duration: moveCoinsBackDuration}, 0);
        }

        // Собираем последовательные анимации
        globalTimeline.add(moveCoinsOnBowls);
        globalTimeline.add(moveBowls, ">" + beforeWeighingPauseDuration);
        globalTimeline.add(moveCoinsBack, ">" + afterWeighingPauseDuration);
    }

    /*
    ***************************************
    ** Показать монеты в качестве ответа **
    ***************************************
    */

    // answerCnt - всего монет в ответе
    // n - порядковый номер монеты среди ответа (нумерация с 0)
    this.coinAnswerPosition = (answerCnt, n) => {
        return {
            x : Math.round( (sceneWidth - this.coinSize * answerCnt) * 0.5 ) + this.coinSize * n,
            y : Math.round(sceneHeight * 0.27) - this.coinSize
        }
    }

    // Показать монеты в центре в качестве ответа
    // answerCoinsNum - номер монет в ответе (нумерация с 1)
    // duration - продолжительность анимации в милисекундах
    // callback - действие по завершении
    this.showAnswerCoins = (answerCoinsNum, duration, callback) => {
        // Сортируем и оставляем уникальные номера монет, переходим к нумерации с 0
        let ans = answerCoinsNum.slice().sort( (a,b) => (a-b) ).filter( (v, i, arr) => (i === 0 || arr[i-1] < v) ).map( (v) => (v-1));

        let timeline = window.gsap.timeline({onComplete: callback});

        for(let i in this.coins){
            if(ans.find( (x) => (x === parseInt(i))) !== undefined){
                let ind = ans.findIndex( (x) => (x === parseInt(i)));
                let vec = this.vector(this.coinHeapPosition(i), this.coinAnswerPosition(ans.length, ind));
                timeline.to(this.coinsImg[i], {x: vec.x, y: vec.y, duration: duration / 1000}, 0);
            }
            else{
                timeline.to(this.coinsImg[i], {y: - Math.round(sceneHeight * 0.25), duration: duration / 1000}, 0);
            }
        }

    }


}