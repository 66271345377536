function TaskExecutorInterface(taskExecutor){

    /*
        Пауза выполнения на произведение действия
    */    

    // Поставить выполнение программы на паузу для завершения выполнения действия
    this.waitForActionComplete = taskExecutor.waitForActionComplete;
    // Действие завершено
    this.actionComplete = taskExecutor.actionComplete;

    /*
        Аварийное завершение работы программы:
        failExecution(errorText)
    */
    this.failExecution = taskExecutor.failExecution;
    //this.failExecution = (message) => {alert(message);};
}

export default TaskExecutorInterface;