import React from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';

import TaskSwitchItem from './TaskSwitchItem.js'

import './TaskSwitcher.scss'

class TaskSwitcher extends React.Component{
    /*
        props.taskResult: массив элементов
            {
                taskNum - номер задачи
                tries - количество попыток по задаче
                score - количество баллов по задаче
                passed - решена ли полностью задача
            }
        
        this.props.curTaskNum - номер текущей задачи
        this.props.onTaskClick(taskNum) - обработчик нажатия на задачу
    */

    taskClickHandler = (taskNum) => {
        this.props.onTaskClick(taskNum);
    }

    componentDidUpdate(){
    }

    render(){
        return(
            <ButtonGroup className="TaskSwitcher">
                    {
                        this.props.taskResults.map(
                            (taskResult) =>
                            <TaskSwitchItem 
                                key = {taskResult.taskNum.toString()}
                                sz = {this.props.sz}
                                taskResult = {taskResult}
                                isActive = {taskResult.taskNum === this.props.curTaskNum}
                                onTaskClick = {this.taskClickHandler}
                            />
                        )
                    }                
            </ButtonGroup>
        );
    }
}

export default TaskSwitcher;