import React from 'react';

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {sha256} from 'js-sha256/src/sha256.js'

import InfoLoader from '../datatools/InfoLoader.js';
import ContestsList from '../mainworkflow/ContestsList.js'
import SupertasksList from '../mainworkflow/SupertasksList.js'
import Supertask from '../supertask/Supertask.js'
import ContestPassModal from '../mainworkflow/ContestPassModal.js'


import './WorkBlock.scss'

class WorkBlock extends React.Component{

    constructor(props){
        super(props);

        this.navPath = [
            {
                title: "Главная",
                onClick: this.handleClickHome
            }
        ];

        //this.contestsList = ['000_testcontest'];

        this.contestInfo = {};        
        this.supertaskInfo = {};

        //this.supertasksList = ['000_test'];
        

        this.state = {
            state : 'contestsList',
            navPath : this.navPath
        }
    }

    /*
        Lifecycle
    */

    componentDidMount(){
        InfoLoader.loadContests(this.handleContestNamesLoaded);
    }

    // Handlers

    handleContestNamesLoaded = (success, contestNames) => {
        if(success){
            this.setState({
                state : 'contestsList',
                contestsList : contestNames
            });            
        }
        else{
            this.setState({
                contestsListLoadFailed : true
            });
        }
    }

    handleContestInfoLoaded = (contestName, contestInfo) => {
        let passed = false;
        if(this.contestInfo[contestName] && this.contestInfo[contestName].passed){
            passed = true;
        }
        this.contestInfo[contestName] = contestInfo;
        if(passed){
            this.contestInfo[contestName].passed = true;
        }
    }        

    handleSupertaskInfoLoaded = (supertaskName, supertaskInfo) => {
        this.supertaskInfo[supertaskName] = supertaskInfo;
    }    

    handleClickContest = (contestName) => {
        if(this.contestInfo[contestName].supertasksList){
            if(this.contestInfo[contestName].passwordSHA256 && !this.contestInfo[contestName].passed){
                this.setState({
                    state : "contestPassModal",
                    contestName : contestName
                });
            }
            else{
                this.enterContest(contestName);
            }
        }
    }

    handleContestPassModalHide = () => {
        this.setState({
            state : "contestsList"
        });        
    }

    handleContestPassModalSubmit = (contestName, password) => {
        let passwordSHA256 = sha256(password);
        if(passwordSHA256 === this.contestInfo[contestName].passwordSHA256){
            this.contestInfo[contestName].passed = true;
            this.enterContest(contestName);
        }
        else{
            this.setState({
                state : "contestPassFailed"
            });               
        }
    }

    handleClickSupertask = (supertaskName, taskNum) => {
        let pos = this.navPath.length;
        this.navPath.push({
            title : this.supertaskInfo[supertaskName].title,
            onClick : () => {
                this.navPath = this.navPath.slice(0, pos+1);
                this.showSupertask(supertaskName, 1);
            }
        });
        this.showSupertask(supertaskName, taskNum);
    }

    handleClickHome = () => {

        this.navPath = this.navPath.slice(0,1);
        this.setState({
            state : 'contestsList',
            navPath : this.navPath
        });
    }

    // Change view
    
    enterContest = (contestName) => {
        let pos = this.navPath.length;
        this.navPath.push({
            title : this.contestInfo[contestName].title,
            onClick : () => {
                this.navPath = this.navPath.slice(0, pos+1);
                this.showContest(contestName);
            }
        });
        this.showContest(contestName);
    }

    showContest(contestName){
        if(this.contestInfo[contestName].supertasksList){
            this.setState({
                state : "supertasksList",
                supertasksList : this.contestInfo[contestName].supertasksList,
                navPath : this.navPath
            });    
        }
    }

    showSupertask(supertaskName, taskNum){
        this.setState({
            state : 'supertask',
            supertaskName : supertaskName,
            taskNum : taskNum,
            navPath : this.navPath
        });
    }

    render(){
        return(
            <div id="workBlock">
                <Breadcrumb>

                    {this.state.navPath.map(
                        (item, ind, arr) => {
                            return (
                                <Breadcrumb.Item key={ind}
                                    onClick = {item.onClick}
                                    active={ind === arr.length-1}
                                >
                                    {item.title}
                                </Breadcrumb.Item>
                            );
                        }
                    )}

                </Breadcrumb>

                {this.state.state === 'contestsList' &&
                    (this.state.contestsList ?
                        <ContestsList
                            nameList = {this.state.contestsList}
                            onInfoLoaded = {this.handleContestInfoLoaded}
                            onClick = {this.handleClickContest}
                        />
                    :
                        (this.contestsListLoadFailed ?
                            'Ошибка загрузки данных'
                            : <Spinner animation="border"></Spinner>)
                    )
                }
                {
                    this.state.state === 'contestPassModal' &&
                    <ContestPassModal
                        show = {this.state.state === 'contestPassModal'}
                        contestName = {this.state.contestName}
                        onHide = {this.handleContestPassModalHide}
                        onSubmit = {this.handleContestPassModalSubmit}
                    >

                    </ContestPassModal>
                }
                {
                    this.state.state === 'contestPassFailed' &&
                    <Modal
                        show = {this.state.state === 'contestPassFailed'}
                        onHide = {this.handleContestPassModalHide}
                    >
                        <Modal.Header closeButton>
                            Неверное кодовое слово.
                        </Modal.Header>
                        <Modal.Footer>
                            <Button onClick={this.handleContestPassModalHide}>Закрыть</Button>
                        </Modal.Footer>
                    </Modal>
                }                
                {this.state.state === 'supertasksList' &&
                    <SupertasksList
                        namesList={this.state.supertasksList}
                        dataHub = {this.props.dataHub}
                        onInfoLoaded = {this.handleSupertaskInfoLoaded}
                        onClick = {this.handleClickSupertask}
                    />
                }
                {this.state.state === 'supertask' &&
                    <Supertask 
                        supertaskName = {this.state.supertaskName}
                        taskNum = {this.state.taskNum}
                        dataHub = {this.props.dataHub}/>
                }
            </div>
        );
    }
}

export default WorkBlock;