import {setAttrs} from '../../../../../commontools/setAttributes.js';

const SVGNS = "http://www.w3.org/2000/svg";

function LeftWall(col, rowBegin, rowEnd){
    this.col = col;
    this.bottomRow = Math.min(rowBegin, rowEnd);
    this.topRow = Math.max(rowBegin, rowEnd);

    this.elem = document.createElementNS(SVGNS, "line");

    this.placeOnGrid = (grid, style) => {
        this.elem.remove();

        setAttrs(this.elem,{
                x1 : (this.col - grid.leftColumn) * grid.h + grid.leftColumnXleft,
                y1 : grid.bottomRowYBottom - (this.bottomRow - grid.bottomRow) * grid.h,
                x2 : (this.col - grid.leftColumn) * grid.h + grid.leftColumnXleft,
                y2 : grid.bottomRowYBottom - (this.topRow + 1 - grid.bottomRow) * grid.h,
                style : style
            }
        );
        
        grid.scene.appendChild(this.elem);
        grid.leftWalls.push(this);
    }

    this.hasWallOnLeft = (col, row) => {
        if(col === this.col && this.bottomRow <= row && row <= this.topRow){
            return true;
        }
        else{
            return false;
        }
    }

    this.hasWallOnRight = (col, row) => {
        if(col === this.col - 1 && this.bottomRow <= row && row <= this.topRow){
            return true;
        }
        else{
            return false;
        }
    }    
}

function BottomWall(row, colBegin, colEnd){
    this.row = row;
    this.leftCol = Math.min(colBegin, colEnd);
    this.rightCol = Math.max(colBegin, colEnd);

    this.elem = document.createElementNS(SVGNS, "line");

    this.placeOnGrid = (grid, style) => {
        this.elem.remove();

        setAttrs(this.elem,{
                x1 : (this.leftCol - grid.leftColumn) * grid.h + grid.leftColumnXleft,
                y1 : grid.bottomRowYBottom - (this.row - grid.bottomRow) * grid.h,
                x2 : (this.rightCol + 1 - grid.leftColumn) * grid.h + grid.leftColumnXleft,
                y2 : grid.bottomRowYBottom - (this.row - grid.bottomRow) * grid.h,
                style : style
            }
        );
        
        grid.scene.appendChild(this.elem);
        grid.bottomWalls.push(this);
    }

    this.hasWallOnTop = (col, row) => {
        if(row === this.row - 1 && this.leftCol <= col && col <= this.rightCol){
            return true;
        }
        else{
            return false;
        }
    }

    this.hasWallOnBottom = (col, row) => {
        if(row === this.row && this.leftCol <= col && col <= this.rightCol){
            return true;
        }
        else{
            return false;
        }
    }    
}

export {LeftWall, BottomWall};