function CodeExecutor(workspace){
    // workspace - Blockly workspace

    this.workspace = workspace;

    /*
        Инициализация
    */

    
    window.Blockly.JavaScript.addReservedWords('code');
    window.Blockly.JavaScript.addReservedWords('__highlightBlock');
    window.Blockly.JavaScript.addReservedWords('__startNextStep');
    window.Blockly.JavaScript.addReservedWords('__consoleLog');
    window.Blockly.JavaScript.STATEMENT_PREFIX = '__startNextStep();\n__highlightBlock(%1);\n';

    /*
        Константы
    */
   
    // Интервал в мс до следующей попытки продолжения выполнения программы в случае постановки программы на паузу или ожидания завершения действия
    this.WAIT_RESUME_EXECUTION_INTERVAL = 10;

    /*
        Сообщения
    */
    this.msgs = {
        onSuccessfulFinished : 'Программа завершена успешно',
        onMaxStepsNumExceeded : 'Превышено максимальное количество шагов программы'
    }

    /*
        Выполнение кода
    */

    // Сделано шагов
    this.doneSteps = -1;

    // Программа запущена
    this.isProgramRunning = false;

    // Программа поставлена на паузу
    this.isProgramOnPause = false;

    // Признак того, что выполняется действие, блокирующее дальнейшее выполнение программы
    this.isActionPerforming = false;

    // Интервал шага в мс
    this.stepInterval = 1;

    // JS Interpreter
    this.interpreter = null;

    /*
        Переменные управления процессом выполнения кода
    */

    // Завершается предыдущий шаг программы
    this.finishingStep = false;
    this.onManualNextStep = false;

    /*
        Выполнить следующий шаг интерпретатора
    */

    this.nextStepRun = () => {
        try{
            // Если выполнение программы не остановлено - выполняем следующий шаг, иначе - завершаем выполнение функции
            if(this.isProgramRunning){
                if(this.isProgramOnPause || this.isActionPerforming || this.onManualNextStep){
                    // Если выполнение программы на паузе - повторяем вызов через установленный интервал
                    this.timerId = window.setTimeout(this.nextStepRun, this.WAIT_RESUME_EXECUTION_INTERVAL);
                }
                else if (this.interpreter.step()) {
                    // Если удалось совершить очередной шаг интерпретатора
                    //let nextStepInterval = 0;
                    let finished = this.finishingStep;
                    while(!finished){
                        finished = !this.interpreter.step() || this.finishingStep;
                    }
                    this.finishingStep = false;
                    /*if(this.finishingStep){
                        nextStepInterval = this.stepInterval;
                        this.finishingStep = false;
                    }*/
                    //console.log("nextStepInterval : " + nextStepInterval);
                    this.timerId = window.setTimeout(this.nextStepRun, this.stepInterval);
                }
                else{
                    // Программа завершена
                    this.stopProgramExecution(true, this.msgs.onSuccessfulFinished);
                }
            }
        }
        catch(err){
            this.stopProgramExecution(false, err.message);
        }
    }

    /*
        Выполнить следующий шаг алгоритма ручном режиме
    */

    this.nextStepManual = () => {
        try{
            if(!this.finishingStep){
                if (this.interpreter.step()){
                    //console.log("nextStepManual");
                    this.manualNextStepTimerId = window.setTimeout(this.nextStepManual, 0);
                }
                else{
                    this.stopProgramExecution(true, this.msgs.onSuccessfulFinished);
                }
            }
            else{
                this.finishingStep = false;
                this.onManualNextStep = false;
            }
        }
        catch(err){
            this.stopProgramExecution(false, err.message);
        }      
    }

    /* 
        Выполнить код
        codeToExec - код, который необходимо выполнить
        execProps:
            stepInterval - интервал выполнения шагов интерпретатора в мс
            maxSteps - максимальное кол-во шагов в программе, если < 0 - без ограничений
            isProgramOnPause - поставлена ли выполнение программы на паузу
            makeWrappers(interpreter, scope) - создание оберток функция для интерпретатора
            onExecComplete(success, message) - вызывается после завершения выполнения программы, не вызывается в случае внешнего прерывания работы программы
                        success = true - если программа отработала до конца
                                = false - если выполнение программы было прервано исключением
                        message - сообщение об ошибке, если завершено успешно - ''
    */
    this.execute = (code, execProps) => {

        this.doneSteps = -1;
        this.maxSteps = execProps.maxSteps;
        this.isProgramRunning  = true;
        this.isProgramOnPause = execProps.isProgramOnPause;
        this.isActionPerforming = false;
        this.finishingStep = false;
        this.onManualNextStep = false;        
        this.stepInterval = execProps.stepInterval;
        this.onExecComplete = execProps.onExecComplete;

        if(execProps.makeWrappers){
            this.makeCustomWrappers = execProps.makeWrappers;
        }
        else{
            this.makeCustomWrappers = function(interpreter, scope){
            }
        }

        this.interpreter = new window.Interpreter(code, this.makeWrappers);
        this.nextStepRun();
    }

    /*
        makeWrappers
    */

    this.makeWrappers = (interpreter, scope) => {
        
        // Add an API function for highlightBlock
        var wrapper = (id) => {
            return this.workspace.highlightBlock(id);
        };
        interpreter.setProperty(scope, '__highlightBlock',
            interpreter.createNativeFunction(wrapper));       
        
        // Add an API function for incDoneSteps
        wrapper = () => {
            this.startNextStep();
        };
        interpreter.setProperty(scope, '__startNextStep',
            interpreter.createNativeFunction(wrapper));     
        
        // Add an API function for console.log

        wrapper = (message) => {
            console.log(message);
        };
        interpreter.setProperty(scope, '__consoleLog',
            interpreter.createNativeFunction(wrapper));        

        this.makeCustomWrappers(interpreter, scope);
    }

    /*
        Контроль кол-ва шагов
    */

    this.startNextStep = () =>{
        this.doneSteps++;
        if(this.maxSteps >= 0 && this.doneSteps > this.maxSteps){
            // Если превышено максимальное кол-во шагов
            this.maxStepsExceeded();
        }
        else{
            if(this.doneSteps > 0){
                this.finishingStep = true;
            }
            else{
                this.finishingStep = false;
            }
        }
    }

    this.maxStepsExceeded = () =>{
        this.stopProgramExecution(false, 
            this.msgs.onMaxStepsNumExceeded + ": " + this.doneSteps + " > " + this.maxSteps + ".");
    }

    /*
    ***************************************************************
        Интерфейсные методы
    ***************************************************************
    */

    /*
        Остановка выполнения программы
    */

    this.stopProgramExecution = (success, message) => {
        if(this.isProgramRunning){
            this.isProgramRunning = false;
            this.onManualNextStep = false;
    
            if(this.timerId){
                window.clearTimeout(this.timerId);
            }
            if(this.manualNextStepTimerId){
                window.clearTimeout(this.manualNextStepTimerId);
            }
            
            this.workspace.highlightBlock(0);
    
            this.interpreter = null;
    
            this.onExecComplete(success, message);
        }
    }    

    /*
        Постановка выполнения на паузу
    */

    this.setProgramOnPause = (isProgramOnPause) => {
        this.isProgramOnPause = isProgramOnPause;
    }

    /*
        Выполнение программы по шагам
    */

    this.manualNextStep = () => {
        // Программу на паузе разрешено выполнять по шагам
        if(this.interpreter != null && this.isProgramRunning && !this.isActionPerforming && !this.onManualNextStep){
            this.onManualNextStep = true;
            this.nextStepManual();
        }
    }

    /*
        Пауза выполнения для произведения действия (анимация, запрос данных)
    */

    // Ожидать завершения выполнения действия
    this.waitForActionComplete = () => {
        this.isActionPerforming = true;
    }

    // Выполнение действия завершено
    this.actionComplete = () => {
        this.isActionPerforming = false;
    }

    /*
        Изменение скорости выполнения программы
    */

    this.setStepInterval = (stepInterval) => {
        this.stepInterval = stepInterval;
    }
}

export default CodeExecutor;