import React from 'react';

class Blocklyzone extends React.Component{
    
    createWorkspace = () =>{

        let toolbox = document.getElementById('toolboxXML');
        if(!toolbox){
            toolbox = document.createElement('xml');
            toolbox.id = 'toolboxXML';
            document.head.appendChild(toolbox);
        }
        this.toolbox = toolbox;
        this.toolbox.innerHTML = this.props.toolboxXML;

        this.workspace = window.Blockly.inject('blocklyDiv',
            {
                toolbox : this.toolbox,
                horizontalLayout:false,
                collapse: false,
                sounds: false,
                scrollbars: true,
                zoom:
                {
                    controls: true,
                    wheel: true,
                    startScale: 0.8,
                    maxScale: 3,
                    minScale: 0.3,
                    scaleSpeed: 1.2
                },
                grid:
                {
                    spacing: 20,
                    length: 3,
                    colour: '#ccc',
                    snap: true
                }                
            }
        );
        this.setWidth();
        this.workspace.addChangeListener(this.onUpdateWorkspaceDOM);
    }

    getWorkspace = () => {
        return this.workspace;
    }

    /*
        Lifecycle
    */    

    componentDidMount(){
        if(this.props.taskScriptLoaded){
            this.createWorkspace();
        }
        window.addEventListener('resize', this.setWidth);
    }

    shouldComponentUpdate(){
        if(this.props.taskScriptLoaded){
            return false;
        }
        else{
            return true;
        }
    }

    componentDidUpdate(){
        if(this.workspace){
            this.workspace.dispose();
            this.workspace = null;
        }
             
        if(this.props.taskScriptLoaded){
            this.createWorkspace();
        }
    }

    componentWillUnmount(){
        if(this.workspace){
            this.workspace.dispose();
        }
        window.removeEventListener('resize', this.setWidth);
    }

    /*
        Установка ширины workspace
    */

    setWidth = () => {
        let blocklyzone = document.getElementById('blocklyzone');
        let parent = blocklyzone.offsetParent;

        let width = parent.clientWidth - 780;

        if(width < 640){
            width = parent.clientWidth - 100;
            if(width < 640){
                width = 640;
            }    
        }

        blocklyzone.style.width = width + "px";

        window.Blockly.svgResize(this.workspace);
    }

    /*
        Блокирование workspace
    */

    unselectAllBlocks = () =>{
        this.workspace.getAllBlocks(false).forEach(
            (block) => {
                block.unselect();
            });
    }

    blockWorkspace = () => {
        let blocklyDiv = document.getElementById('blocklyDiv');
        let blocklyCover = document.getElementById('blocklyCover');
        blocklyCover.style.left = blocklyDiv.offsetTop;
        blocklyCover.style.top = blocklyDiv.offsetLeft;
        blocklyCover.style.display = "block";

        this.unselectAllBlocks();
    }

    unblockWorkspace = () => {
        let blocklyCover = document.getElementById('blocklyCover');
        blocklyCover.style.display = "none";
    }

    /*
        При изменении кода
    */
   
    onUpdateWorkspaceDOM = () =>{
        let xml = window.Blockly.Xml.workspaceToDom(this.workspace);
        let text = window.Blockly.Xml.domToText(xml);
        this.props.onWorkspaceDOMChange(text);
    }

    render(){
        return(
            <div id="blocklyzone">
                <div id="blocklyDiv">            
                </div>
                <div id="blocklyCover">
                </div>
            </div>
        );
    }
}

export default Blocklyzone;