import {setAttrs} from '../../../../../commontools/setAttributes.js';

import Sprite from './Sprite.js';
import {LeftWall, BottomWall} from './Wall.js';
import {Fill} from './Fill.js';

const SVGNS = "http://www.w3.org/2000/svg";

/*
    All durations in ms
*/

function SquareGrid(scene, width, height, rows, lineStyle, animDuration, onCellClick){
    this.scene = scene;
    this.sceneWidth = width;
    this.sceneHeight = height;    
    // Кол-во строк
    this.rows = rows;
    // Продолжительность анимации в мс при перемещении спрайтов по клику мыши
    if(animDuration){
        this.animDuration = animDuration;
    }
    else{
        this.animDuration = 0;
    }
    
    // Перехватчик - функция от col, pos - координат клетки, по которой был произведён клик
    this.onCellClick = onCellClick;

    // Ширина клетки
    this.h = Math.round(this.sceneHeight / rows);
    // Кол-во столбцов
    this.columns = Math.floor(this.sceneWidth / this.h);

    this.leftColumn = 0;
    this.bottomRow = 0;

    this.leftColumnXleft = (this.sceneWidth / 2) - Math.round(this.h * (this.columns / 2));
    this.bottomRowYBottom = this.sceneHeight;

    // Линии сетки
    this.hLines = [];
    this.vLines = [];

    // Закраска клеток
    this.fillsMap = new Map(); // key = "col,row"    
    this.fillsGroup = document.createElementNS(SVGNS, "g");
    this.scene.appendChild(this.fillsGroup);    

    // Стены
    this.leftWalls = [];
    this.bottomWalls = [];
    this.wallsGroup = document.createElementNS(SVGNS, "g");
    this.scene.appendChild(this.wallsGroup);

    // Спрайты
    this.sprites = [];
    this.spritesGroup = document.createElementNS(SVGNS, "g");
    this.scene.appendChild(this.spritesGroup);        

    // Возможность выбирать спрайт
    this.selectSpriteMode = false;

    // Выбранный спрайт
    this.selectedSprite = null;

    // Инициализация
    this.init = () => {

        // Удаляем линии сетки
        for(let line of this.hLines){
            line.remove();
        }
        this.hLines = [];

        for(let line of this.vLines){
            line.remove();
        }
        this.vLines = [];

        // Удаляем закраски
        for(let f in this.fillsMap.entries){
            f.elem.remove();
        }
        this.fillsMap = new Map();

        // Удаляем стены
        for(let wall of this.leftWalls){
            wall.elem.remove();
        }
        this.leftWalls = [];    
        
        for(let wall of this.bottomWalls){
            wall.elem.remove();
        }
        this.bottomWalls = [];     
        
        // Удаялем спрайты
        for(let sprite of this.sprites){
            sprite.elem.remove();
        }
        this.sprites = [];        

        for(let i = 0; i <= this.rows; i++){
            let hline = document.createElementNS(SVGNS, "line");
            let y = Math.round(i * this.sceneHeight / this.rows);

            setAttrs(hline, {
                x1 : 0,
                y1 : y,
                x2 : this.sceneWidth,
                y2 : y,
                style : lineStyle
            });

            this.scene.appendChild(hline);
            this.hLines.push(hline);
        }

        let xStart = this.leftColumnXleft;

        for(let i = 0; i <= this.columns; i++){
            let vline = document.createElementNS(SVGNS, "line");
            let x = xStart + i * this.h;

            setAttrs(vline,{
                x1 : x,
                y1 : 0,
                x2 : x,
                y2 : this.sceneHeight,
                style : lineStyle
            });
            this.scene.appendChild(vline);
            this.vLines.push(vline);            
        }
    }

    this.removeClickListener = () => {
        this.scene.removeEventListener("click", this.handleSceneClick);
    }

    /*
        *********************************************
        Размещение спрайта
        *********************************************
    */

    // Разместить SVG-спрайт
    // Область спрайта (свойство viewBox) должна быть квадатной, чтобы спрайт отображался правильно
    this.placeSVGSprite = (href, col, row, direction) => {
        let svg = document.createElementNS(SVGNS, "image");
        setAttrs(svg, {
            x : 0,
            y : 0,
            height : Math.round(this.h * 0.9),
            width : Math.round(this.h * 0.9)
        });

        svg.setAttributeNS('http://www.w3.org/1999/xlink', "href", href);

        this.spritesGroup.appendChild(svg);

        let sprite = new Sprite(this, svg, Math.round(this.h * 0.9), col, row, direction);
        this.sprites.push(sprite);
        return sprite;
    }

    // Переместить окно на данное кол-во столбцов и строк
    this.moveWindowOn = (nCols, nRows, duration, onComplete) => {
        this.leftColumn += nCols;
        this.bottomRow += nRows;

        let timeline = window.gsap.timeline({onComplete: onComplete});

        let grid = this;

        if(nCols !== 0){
            for(let vLine of this.vLines){
                let xCur = window.gsap.getProperty(vLine, "x");
                timeline.add(
                    window.gsap.to(vLine, {
                        x : xCur - nCols * this.h, 
                        duration : duration / 1000,
                        onUpdate : () => {
                            let x1 = parseInt(vLine.getAttribute("x1"));
                            let x =  x1 + window.gsap.getProperty(vLine, "x");
                            
                            if(x < 0){
                                setAttrs(vLine, {
                                    x1 : x1 + (grid.columns + 1) * grid.h,
                                    x2 : x1 + (grid.columns + 1) * grid.h
                                });
                            }
                            else if(x > this.sceneWidth){
                                setAttrs(vLine, {
                                    x1 : x1 - (grid.columns + 1) * grid.h,
                                    x2 : x1 - (grid.columns + 1) * grid.h
                                });
                            }
                        }
                    }), 0);
            }
        }

        if(nRows !== 0){
            for(let hLine of this.hLines){
                let yCur = window.gsap.getProperty(hLine, "y");
                timeline.add(
                    window.gsap.to(hLine, {
                        y : yCur + nRows * this.h, 
                        duration : duration / 1000,
                        onUpdate : () => {
                            let y1 = parseInt(hLine.getAttribute("y1"));
                            let y =  y1 + window.gsap.getProperty(hLine, "y");
                            
                            if(y < 0){
                                setAttrs(hLine, {
                                    y1 : y1 + (grid.rows + 1) * grid.h,
                                    y2 : y1 + (grid.rows + 1) * grid.h
                                });
                            }
                            else if(y > this.sceneHeight){
                                setAttrs(hLine, {
                                    y1 : y1 - (grid.rows + 1) * grid.h,
                                    y2 : y1 - (grid.rows + 1) * grid.h
                                });
                            }
                        }
                    }), 0);
            }
        }

        /*
            Перемещение объектов
        */
        
        // Спрайты

        for(let s of this.sprites){
            s.windowColShift += nCols;
            s.windowRowShift += nRows;
            timeline.add(s.moveTo(s.col, s.row, duration),0);
        }

        // Левые стены

        for(let wall of this.leftWalls){
            let xCur = window.gsap.getProperty(wall.elem, "x");
            let yCur = window.gsap.getProperty(wall.elem, "y");
            timeline.add(
                window.gsap.to(wall.elem, {
                    x : xCur - nCols * this.h,
                    y : yCur + nRows * this.h,
                    duration : duration / 1000
                }), 0);
        }

        // Нижние стены

        for(let wall of this.bottomWalls){
            let xCur = window.gsap.getProperty(wall.elem, "x");
            let yCur = window.gsap.getProperty(wall.elem, "y");
            timeline.add(
                window.gsap.to(wall.elem, {
                    x : xCur - nCols * this.h,
                    y : yCur + nRows * this.h,
                    duration : duration / 1000
                }), 0);
        }
        
        // Закрашенные клетки

        for(let fill of this.fillsMap.values()){
            let xCur = window.gsap.getProperty(fill.elem, "x");
            let yCur = window.gsap.getProperty(fill.elem, "y");
            timeline.add(
                window.gsap.to(fill.elem, {
                    x : xCur - nCols * this.h,
                    y : yCur + nRows * this.h,
                    duration : duration / 1000
                }), 0);
        }
    }

    /*
        *********************************************
        Выбор клетки и спрайта
        *********************************************
    */

    this.getLocalByClientXY = (clientX, clientY) => {
        let box = this.scene.getBoundingClientRect();
        let borderTopWidth = parseInt( window.getComputedStyle( this.scene ).borderTopWidth, 10 );
        let borderLeftWidth = parseInt( window.getComputedStyle( this.scene ).borderLeftWidth, 10 );
        let sceneX = clientX - box.left - borderLeftWidth;
        let sceneY = clientY - box.top - borderTopWidth;
        return {
            x : sceneX,
            y : sceneY
        }; 
    }

    this.getCell = (x, y) => {
        let col = Math.floor( (x - this.leftColumnXleft) / this.h) + this.leftColumn;
        let row = Math.floor( (this.bottomRowYBottom - y) / this.h) + this.bottomRow;
        return {
            col : col,
            row : row
        }
    }

    this.findSpriteInCell = (col, row) => {
        let res = null;
        for(let s of this.sprites){
            if(s.col === col && s.row === row){
                res = s;
                break;
            }
        }

        return res;
    }

    this.findSpriteByXY = (x, y) => {
        let cell = this.getCell(x, y);
        let col = cell.col;
        let row = cell.row;
        return this.findSpriteInCell(col, row);
    }

    /*
        *********************************************
        Выбор и перемещение спрайта
        *********************************************
    */

    this.setSpriteSelected = (sprite) => {
        if(sprite.selectable){
            let opacity = 0.5;
            if(sprite.elem.style.opacity){
                opacity = sprite.elem.style.opacity * 0.5;
            }
            sprite.elem.style.opacity = opacity;
            this.selectedSprite = sprite;            
        }
    }

    this.setSpriteUnselected = (sprite) => {
        if(sprite === this.selectedSprite){
            sprite.elem.style.opacity *= 2;
            this.selectedSprite = null;
        }
    }

    this.selectOrMoveSpriteByXY = (x, y, duration, onComplete) => {
        let cell = this.getCell(x, y);
        let sprite = this.findSpriteByXY(x,y);
        let timeline = window.gsap.timeline({
            onComplete: onComplete
        });
        if(sprite === null){
            if(this.selectedSprite !== null){
                timeline.add(this.selectedSprite.moveTo(cell.col, cell.row, duration));
                this.setSpriteUnselected(this.selectedSprite);
            }
        }
        else{
            if(sprite === this.selectedSprite){
                this.setSpriteUnselected(this.selectedSprite);
            }
            else{
                if(this.selectedSprite !== null){
                    this.setSpriteUnselected(this.selectedSprite);
                }
                this.setSpriteSelected(sprite);
            }
        }
    }

    /*
        *********************************************
        Обработка события клика
        *********************************************
    */

    this.handleSceneClick = (event) => {
        let localPos = this.getLocalByClientXY(event.clientX, event.clientY);
        let cell = this.getCell(localPos.x, localPos.y);

        if(this.selectSpriteMode){
            this.selectOrMoveSpriteByXY(localPos.x, localPos.y, this.animDuration,
                () => {
                    if(this.onCellClick){
                        this.onCellClick(cell.col, cell.row);
                    }
                });
        }
        else{
            if(this.onCellClick){
                this.onCellClick(cell.col, cell.row);
            }
        }
    }

    this.scene.addEventListener("click", this.handleSceneClick);

    /*
        *********************************************
        Стены
        *********************************************
    */
   
    this.placeLeftWall = (col, rowBegin, rowEnd, style) => {
        let wall = new LeftWall(col, rowBegin, rowEnd);
        wall.placeOnGrid(this, style);
    }

    this.placeBottomWall = (row, colBegin, colEnd, style) => {
        let wall = new BottomWall(row, colBegin, colEnd);
        wall.placeOnGrid(this, style);
    }
    
    this.hasWallOnWest = (col, row) => {
        let res = false;

        for(let wall of this.leftWalls){
            res = res || wall.hasWallOnLeft(col,row);
            if(res){
                break;
            }
        }

        return res;
    }

    this.hasWallOnEast = (col, row) => {
        let res = false;

        for(let wall of this.leftWalls){
            res = res || wall.hasWallOnRight(col,row);
            if(res){
                break;
            }
        }

        return res;
    }

    this.hasWallOnNorth = (col, row) => {
        let res = false;

        for(let wall of this.bottomWalls){
            res = res || wall.hasWallOnTop(col,row);
            if(res){
                break;
            }
        }

        return res;
    } 
    
    this.hasWallOnSouth = (col, row) => {
        let res = false;

        for(let wall of this.bottomWalls){
            res = res || wall.hasWallOnBottom(col,row);
            if(res){
                break;
            }
        }

        return res;
    }

    /*
        *********************************************
        Закрашивание клетки
        *********************************************
    */

    this.fillCell = (col, row, style) => {
        this.clearFillCell(col, row);
        let key = col + "," + row;
        let fill = new Fill(col, row, this, style);
        this.fillsMap.set(key, fill);
        fill.draw();
    }

    this.clearFillCell = (col, row) => {
        let key = col + "," + row;
        let fill = this.fillsMap.get(key);
        if(fill){
            fill.elem.remove();
        }
        this.fillsMap.delete(key);
    }
        
}

export default SquareGrid;