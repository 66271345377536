import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class ContestPassModal extends React.Component{

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        const password = form["password"].value;

        this.props.onSubmit(this.props.contestName, password);
    }

    render(){
        return(
            <Modal
                show={this.props.show} 
                onHide={this.props.onHide}>
                
                <Modal.Header closeButton>
                    <Modal.Title>Введите кодовое слово</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Card>
                      <Card.Body>
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group>
                            <Form.Label> Кодовое слово </Form.Label>
                            <Form.Control type="text" name="password" placeholder="Введите кодовое слово"></Form.Control>
                            <Form.Text className="text-muted">Данный контест требует ввода кодового слова</Form.Text>
                          </Form.Group>
                          <Button type="submit">Войти</Button>
                        </Form>
                        </Card.Body>
                    </Card>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.onHide}>
                        Закрыть
                    </Button>
                </Modal.Footer>

            </Modal>            
        );
    }
}

export default ContestPassModal;
