import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

import GetUrl from '../datatools/GetUrl.js';
import InfoLoader from '../datatools/InfoLoader.js'

import ContestsListItem from './ContestsListItem.js'

class ContestsList extends React.Component{

    constructor(props){
        /*
            props:
                nameList - список названий контестов
                onInfoLoaded(contestName, contestInfo) - вызывается по завершении загрузки информации по контесту
        */
        super(props);

        this.contestsInfoList = this.props.nameList.map(
            (item) => {
                return {
                    contestName : item,
                    loaded : false
                };
            }
        );

        this.state = {
            contestsInfoList : this.contestsInfoList
        };
    }

    /*
        Lifecycle
    */

    componentDidMount(){
        this.loadContestsInfo();
    }    

    /*
        Load data
    */

    loadContestsInfo = () => {
        for(let i = 0; i < this.contestsInfoList.length; i++){
            InfoLoader.loadContestInfo(this.contestsInfoList[i].contestName, 
                (success, contestInfo) =>{
                    this.handleInfoLoaded(i, success, contestInfo);
            });
        }
    }    

    /*
        Handlers
    */

    handleInfoLoaded(listInd, success, contestInfo){        
        if(success){
            this.contestsInfoList[listInd].loaded = true;
            this.contestsInfoList[listInd].title = contestInfo.title;
            this.contestsInfoList[listInd].desc = contestInfo.desc;
            this.contestsInfoList[listInd].logoSrc = GetUrl.getContestRoot(this.contestsInfoList[listInd].contestName) + contestInfo.logo;
            this.contestsInfoList[listInd].variant = contestInfo.variant;

            // Передаём информацию наверх
            this.props.onInfoLoaded(this.contestsInfoList[listInd].contestName, contestInfo);            
        }
        else{
            this.contestsInfoList[listInd].loadFailed = true;
        }

        this.setState({
            contestsInfoList : this.contestsInfoList
        });
    }

    handleContestClick = (contestName) => {
        this.props.onClick(contestName);
    }

    render(){
        return(
            <Row>
                <Col xs={0} sm={1} md={2} lg={3}></Col>

                <Col xs={12} sm={10} md={8} lg={6}>
                    <ListGroup className="ContestsList">
                        {
                            this.state.contestsInfoList.map(
                                (item) => 
                                <ListGroup.Item
                                    key={item.contestName}
                                    variant={item.variant}
                                    onClick={item.loaded ? ()=>{this.handleContestClick(item.contestName)} : ()=>{}}
                                >
                                    {item.loaded ?
                                        <ContestsListItem
                                            title = {item.title}
                                            desc = {item.desc}
                                            logoSrc = {item.logoSrc}
                                        />
                                        :
                                    (item.loadFailed ? 'Ошибка загрузки данных'
                                        : 
                                        <Spinner animation="border"></Spinner>)
                                    }
                                </ListGroup.Item>
                            )
                        }
                    </ListGroup>
                </Col>

                <Col xs={0} sm={1} md={2} lg={3}>
                </Col>

            </Row>
        );
    }

}

export default ContestsList;