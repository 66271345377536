import React from 'react';
import Button from 'react-bootstrap/Button';

class TaskSwitchItem extends React.Component{
    /*
        props.taskResult - информация о номере задаче и результатах по ней
        props.isActive - является ли данная задача активной
        props.onTaskClick - обработчик нажатия на задачу
    */

    constructor(props){
        super(props);

        this.state = {
            isActive : false,
            isPassed : false,
            isTried : false
        };
    }

    static getDerivedStateFromProps = (props, state)=>{
        let isActive = props.isActive;
        let isPassed = props.taskResult.passed;
        let isTried = (props.taskResult.tries !== 0);
        let sz = props.sz ? " " + props.sz : "";

        return {
            isActive : isActive,
            isPassed : isPassed,
            isTried : isTried,
            sz : sz
        };    
    }

    handleClick = (e) => {
        e.stopPropagation();
        this.props.onTaskClick(this.props.taskResult.taskNum);
    }

    render(){
        return(
            <Button className={"TaskSwitchItem" + (this.state.isActive ? " Active" : "") + this.state.sz}
                variant={this.state.isTried ? (this.state.isPassed ? "task-passed" : "task-in-progress") : "task-not-tried"}
                active={this.state.isActive}
                onClick={this.handleClick}
                style={this.state.isActive ? {color:"white"} : {color:"black"}}
            >
                <div className="TaskSwitchItemTaskNum">
                    <label>{this.props.taskResult.taskNum}</label>
                </div>
                <div className="TaskSwitchItemTaskScore">
                    <label>{this.props.taskResult.score}</label>
                </div>    
                <div className="TaskSwitchItemTaskTries">
                    <label>{this.props.taskResult.tries}</label>
                </div>                              
            </Button>
        )
    }

}

export default TaskSwitchItem;