import {setAttrs} from '../../../../../commontools/setAttributes.js';

const SVGNS = "http://www.w3.org/2000/svg";

export function Fill(col, row, grid, style){

    this.col = col;
    this.row = row;
    this.grid = grid;
    this.style = style;

    this.elem = document.createElementNS(SVGNS, "rect");

    this.draw = () => {
        this.elem.remove();

        setAttrs(this.elem,{
                x : (this.col - grid.leftColumn) * this.grid.h + this.grid.leftColumnXleft,
                y : this.grid.bottomRowYBottom - (this.row - this.grid.bottomRow + 1) * this.grid.h,
                width : this.grid.h,
                height : this.grid.h,
                style : this.style
            }
        );
        
        this.grid.fillsGroup.appendChild(this.elem);
    }

    this.remove = () => {
        this.elem.remove();
    }

}