/*
    Компонент, отвечающий за показ суммы баллов и числа попыток
*/

import React from 'react';

class SumInf extends React.Component{

    render(){
        return(
            <div id="SumInfo" 
                className = {this.props.sumInfo.tasksPassed === this.props.sumInfo.tasksNum ? 'Passed' :
                            (this.props.sumInfo.sumTries > 0 ? 'InProgress' : 'NotTried')}>

               <p id="SumSzSm1">∑</p> 
               <p id="SumSzBg">{this.props.sumInfo.sumScore}</p> 
               <p id="SumSzSm2">{this.props.sumInfo.sumTries}</p>
            </div>
        );
    }
}

export default SumInf;