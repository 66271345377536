//import $ from 'jquery';

import setAttributes from '../../../../commontools/setAttributes.js'

/*
***********************************************************
    Текстовое окно на SVG.
    Пример:

        new TextBox(svg,
            {
            attr : {
                x: 20, y: 20, height : 50, rx : 20, ry : 20
            },
            style : {
                fill: 'grey', stroke: 'black', stroke_width : 5, opacity : 0.5
            },
            styleOnFocus : {
                fill: 'grey', stroke: 'yellow', stroke_width : 7, opacity : 0.5
            },
            textAttr : {
                maxLength : 10
            },
            interactive : true,
            charTransformer : (char) => {
                if('0' <= char && char <= '9'){
                    return char;
                }
                else{
                    return '';
                }
            },
            textFormatter : (text) => {
                let res = '';
                for(let i = 0; i < text.length; i++){
                    if(i > 0 && (text.length - i) % 3 == 0){
                        res += '.';
                    }
                    res += text[i];
                }
                return res;
            }        
        },
        hiddenInput);
***********************************************************        
*/


class TextBox{

    constructor(svg, props, hiddenInput){
        /*
            svg - элемент svg, на котором размещаются объекты
            props - свойства окна
                Обязательные:
                attr - атрибуты окна
                    обязательные:
                    x, y, height
                    факультативные: width, rx, ry
                
                Факультативные:
                style - стиль окна без фокуса
                styleOnFocus - стиль окна с фокусом
                textAttr - атрибуты текста
                interactive - boolean default false - можно ли кликнуть на окно и записывать в него текст

                Обязательно задание хотя бы одного из 2-х свойств:
                    attr.width или textAttr.maxLength

                charTransformer(char) - преобразование добавляемого символа, факультативный

                textFormatter(text) - форматирование текста при выводе, факультативный                    

            hiddenInput - скрытый элемент ввода, факультативный

        */
        this.SVGNS = "http://www.w3.org/2000/svg";

        /*
            Задание свойств объекта
        */
        this.svg = svg;

        // Атрибуты и стиль окна
        this.attr = props.attr;
        if(props.style){
            this.style = props.style;
        }
        else{
            this.style = {};
        }
        
        // Стиль при фокусировке
        if(props.styleOnFocus){
            this.styleOnFocus = props.styleOnFocus;
        }
        else{
            this.styleOnFocus = props.style;
        }

        // Атрибуты текста
        if(props.textAttr){
            this.textAttr = props.textAttr;
        }
        else{
            this.textAttr = {};
        }

        // Максимальная длина текста и ширина окна
        const charWidthFactor = 1.4;
        if(this.textAttr.maxLength){     
            if(!this.attr.width){
                let width = Math.ceil(this.attr.height * this.textAttr.maxLength / charWidthFactor);
                this.attr.width = width;
            }
        }
        else{
            this.textAttr.maxLength = Math.floor(this.attr.width * charWidthFactor / this.attr.height);
        }

        // Интерактивность
        if(props.interactive){
            this.interactive = props.interactive;
        }
        else{
            this.interactive = false;
        }  
        
        if(hiddenInput){
            this.hiddenInput = hiddenInput;
        }
        else{
            this.hiddenInput = null;
        }

        this.charTransformer = props.charTransformer;
        this.textFormatter = props.textFormatter;
        
        /*
            Внутренние свойства
        */

        // Признак фокуса в окне
        this.focused = false;

        /*
            Формируем объект прямоугольника
        */
        this.box = document.createElementNS(this.SVGNS, 'rect');

        //$(this.box).attr(this.attr);
        //$(this.box).attr({style : this.styleToString(this.style)});
        setAttributes(this.box, this.attr);
        setAttributes(this.box, {style : this.styleToString(this.style)});

        this.box.onclick = this.onClick;

        /*
            Формируем объект текста
        */

        this.text = document.createElementNS(this.SVGNS, 'text');
        this.textString = '';
        this.text.onclick = this.onClick;
        //$(this.text).attr({x : this.attr.x + Math.round(this.attr.height * 0.15), y : this.attr.y + Math.round(this.attr.height * 0.7)});
        //$(this.text).attr({style : 'font-size: '  + Math.round(this.attr.height * 0.7) + 'px;'});

        setAttributes(this.text, {x : this.attr.x + Math.round(this.attr.height * 0.15), y : this.attr.y + Math.round(this.attr.height * 0.7)});
        setAttributes(this.text, {style : 'font-size: '  + Math.round(this.attr.height * 0.7) + 'px;'});

        /*
            Добавляем в DOM
        */

        this.svg.appendChild(this.box);
        this.svg.appendChild(this.text);
    }

    /*
        Удаление
    */

    remove = () => {
        this.text.remove();
        this.box.remove();
    }

    /*
        Обработка события
    */    
    
    // Собственное событие
    onClick = () => {
        if(this.hiddenInput != null && this.interactive){
            this.hiddenInput.registerSubscriber(this);
            this.hiddenInput.focus();
            this.focused = true;
            //$(this.box).attr({style : this.styleToString(this.styleOnFocus)});
            setAttributes(this.box, {style : this.styleToString(this.styleOnFocus)});
            this.showText();    
        }
    }

    // Вызывается из hiddenInput
    onKeyDown = (event) =>{
        let keyID = event.keyCode;

        if(keyID === 8){
            this.deleteLastChar();
        }
    }

    // Вызывается из hiddenInput
    onKeyPress = (event) =>{
        this.addChar(this.getChar(event));
    }

    // Вызывается из hiddenInput - действие при переключении фокуса на другой объект
    onBlur = () => {
        this.focused = false;
        //$(this.box).attr({style : this.styleToString(this.style)});
        setAttributes(this.box, {style : this.styleToString(this.style)});
        this.showText();
    }

    /*
        Публичные методы
    */

    // Добавить символ в конец строки
    addChar = (char) => {
        let success = true;
        if(this.textString.length < this.textAttr.maxLength){
            let charToAdd = char;
            if(this.charTransformer){
                charToAdd = this.charTransformer(charToAdd);
            }
            this.textString += charToAdd;
            this.showText();
            success = true;
        }
        else{
            success = false;
        }
        return success;
    }

    // Удалить символ с конца строки
    deleteLastChar = () => {
        let success = true;
        if(this.textString.length > 0){
            this.textString = this.textString.substring(0, this.textString.length - 1);
            this.showText();
            success = true;
        }
        else{
            success = false;
        }
        return success;
    }

    // Получить текст, записанный в окне
    getText = () =>{
        return this.textString;
    }

    // Записать текст в окно
    setText = (text) =>{
        let success = true;
        if(text.length <= this.textAttr.maxLength){
            this.textString = text;
            this.showText();
            success = true;
        }
        else{
            success = false;
        }

        return success;
    }

    /*
        Служебная
    */
    showText = () => {
        let textToShow = this.textString;
        if(this.textFormatter){
            textToShow = this.textFormatter(textToShow);
        }
        if(this.focused){
            textToShow += "|";
        }
        this.text.innerHTML = textToShow;
    }

    /*
        Утилиты
    */

    // Формирование строки стиля
    styleToString(style){
        let res = '';
        for(let name in style){
            if(style[name]){
                let printName = name.replace("_", "-");
                res += printName + ":" + style[name] + ";";
            }
        }
        if(res.length > 0){
            res = res.substring(0, res.length - 1);
        }

        return res;
    }

    // Получение символа из event
    getChar = (event) => {
        if (event.which == null) { // IE
          if (event.keyCode < 32) return null; // спец. символ
          return String.fromCharCode(event.keyCode)
        }
      
        if (event.which !== 0 && event.charCode !== 0) { // все кроме IE
          if (event.which < 32) return null; // спец. символ
          return String.fromCharCode(event.which); // остальные
        }
      
        return null; // спец. символ
      }    
}

export default TextBox;