/*
    Sets attributes for element
*/
function setAttributes(elm, attrs){
    for(let name in attrs){
        elm.setAttribute(name,attrs[name]);
    }
}

function setAttrs(elm, attrs){
    setAttributes(elm, attrs);
}

export {setAttrs};
export default setAttributes;
