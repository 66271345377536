import GetUrl from './GetUrl.js';

class InfoLoader{

    static createRequest = (onLoad) => {
        /*
            Создаёт объект запроса
        */

        let req = new XMLHttpRequest();

        req.onreadystatechange = function(){
            if(this.readyState === 4){
                if(this.status === 200){
                    let respText = this.responseText;
                    try{
                        let obj = JSON.parse(respText);
                        onLoad(true, obj);
                    }
                    catch(err){
                        onLoad(false, null);
                    }
                }
                else{
                    onLoad(false, null);
                }
            }
        }

        return req;
    }

    static loadContests = (onLoad) => {
        /*
            onLoad(success, contestNames) - вызывается по завершении загрузки, получает
                success - успешно ли завершилась загрузка и удалось ли получить JSON объект
                contestNames - массив contestNames, если success == true, иначе null
        */
       
       let req = InfoLoader.createRequest(onLoad);

       let url = GetUrl.getContestsListUrl();
       req.open('GET', url, true);
       req.send();       
    }

    static loadContestInfo = (contestName, onLoad) => {
        /*
            contestName - название контеста
            onLoad(success, contestInfo) - вызывается по завершении загрузки, получает
                success - успешно ли завершилась загрузка и удалось ли получить JSON объект
                contestInfo - объект contestInfo, если success == true, иначе null                
        */

       let req = InfoLoader.createRequest(onLoad);

       let url = GetUrl.getContestRoot(contestName) + 'info.json';
       req.open('GET', url, true);
       req.send();
    }

    static loadSupertaskInfo = (supertaskName, onLoad) => {
        /*
            supertaskName - название суперзадачи
            onLoad(success, supertaskInfo) - вызывается по завершении загрузки, получает
                success - успешно ли завершилась загрузка и удалось ли получить JSON объект
                supertaskInfo - объект supertaskInfo, если success == true, иначе null

        */

        let req = InfoLoader.createRequest(onLoad);

        let url = GetUrl.getSupertaskRoot(supertaskName) + 'info.json';
        req.open('GET', url, true);
        req.send();
    }
}

export default InfoLoader;