/*
    Результат тестирования задачи
        taskNum - номер задачи
        tries - количество попыток по задаче
        score - результат по задаче
        passed - пройдены ли все тесты
*/
class TaskResult{
    constructor(num){
        this.taskNum = num;
        this.tries = 0;
        this.score = 0;
        this.passed = false;
    }
}

export default TaskResult;