import React from 'react';

import '../supertask/Supertask.scss'

import InfoAndTaskSwitcherBlock from './InfoAndTaskSwitcherBlock.js'
import Task from './task/Task.js'
import StatementModal from './StatementModal';

import GetUrl from '../datatools/GetUrl.js'

class Supertask extends React.Component{
    /*
        props:
            supertaskName - название supertask
            taskNum - номер задачи
            dataHub - хаб данных

    */

    constructor(props){
        super(props);

        //Объект для суммарной информации о баллах и попытках
        this.sumInfo = {
                sumScore : 0,
                sumTries : 0,
                tasksNum : 0,
                tasksPassed : 0
        }

        this.state = {
                supertaskName : props.supertaskName,
                sumInfo : this.sumInfo,
                curTaskNum : props.taskNum,                
                scriptLoaded : false,
                showStatement : false,
                statementModalTitle : '',
                statementModalSrc : '',
        };

        

        this.taskRef = React.createRef();

        this.supertaskStatementSrc = GetUrl.getSupertaskRoot(this.props.supertaskName) + "statement.html";
    }

    /*
        React lifecycle
    */

    static getDerivedStateFromProps(props, state){
        let stateChange = {};
        if(props.supertaskName !== state.supertaskName){
            stateChange = {
                supertaskName : props.supertaskName,
                scriptLoaded : false
            }
        }
        return stateChange;
    }

    componentDidMount(){
        if(! this.state.scriptLoaded){
            this.loadScript();
        }
    }

    componentDidUpdate(){
        if(! this.state.scriptLoaded){
            this.loadScript();
        }
    }

    componentWillUnmount(){
        // Перед уничтожением компонента нужно завершить выполнение программы, если она выполняется
        if(this.taskRef && this.taskRef.current){
            this.taskRef.current.stopExecutionImmediately();
        }
    }

    /*
        Загрузка скрипта
    */

    loadScript = ()=>{
        const supertaskScriptBlock = document.getElementById('supertaskScriptBlock');
        const supertaskScript = document.createElement("script");
        supertaskScript.type = "text/javascript";
        supertaskScript.src = "supertasks/" + this.props.supertaskName + "/supertask.js";
        supertaskScript.async = true;
        supertaskScript.onload = () => this.supertaskScriptLoaded();
        supertaskScriptBlock.appendChild(supertaskScript);
    }

    supertaskScriptLoaded(){
        this.supertask = window.supertask;
        let tasksNum = this.supertask.tasksNum;
        let taskData = [];
        let taskResults = [];

        if(this.supertask.createCustomBlocks){
            this.supertask.createCustomBlocks();
        }

        for(let i = 0; i < tasksNum; i++){
            taskData.push({});
            taskData[i].supertaskName = this.props.supertaskName;
            taskData[i].taskNum = i + 1;
            taskData[i].taskScriptSrc = "supertasks/" + this.props.supertaskName + "/" + this.addZero(i+1) + "/task.js";
            taskData[i].taskStatementSrc = "supertasks/" + this.props.supertaskName + "/" + this.addZero(i+1) + "/statement.html";
            taskData[i].supertaskToolboxXML = this.supertask.toolboxXML ? this.supertask.toolboxXML : '';
            taskData[i].workspaceDomText = this.props.dataHub.getSavedWorkspace(this.props.supertaskName, i+1);

            taskResults.push(this.props.dataHub.getTaskResult(this.props.supertaskName, i+1));
        }

        this.taskData = taskData;

        //Обнуляем счётчики и заново просчитываем каждый из показателей суммы баллов и попыток
        this.sumInfo.sumScore = 0;
        this.sumInfo.sumTries = 0;
        this.sumInfo.tasksNum = this.supertask.tasksNum;
        this.sumInfo.tasksPassed = 0;

        for (let i = 0; i < tasksNum; i += 1)
        {
            this.sumInfo.sumScore += taskResults[i].score;
            this.sumInfo.sumTries += taskResults[i].tries;
            this.sumInfo.tasksPassed += (taskResults[i].passed ? 1 : 0);
        }
        
        this.setState({
            sumInfo : this.sumInfo,
            scriptLoaded : true,
            tasksNum : tasksNum,
            taskResults : taskResults
        });
    }

    /*
        Handlers
    */

    taskClickHandler = (taskNum) => {
        if(taskNum !== this.state.curTaskNum){
            this.taskRef.current.stopExecutionImmediately();;
            this.setState({
                curTaskNum : taskNum
            });
        }
    }

    // Фиксирует запуск полного тестирования задачи
    testingStartHandler = (taskNum) => {
        let taskResults = this.state.taskResults;
        // Увеличиваем колчиство попыток только для не сданной задачи
        if(!taskResults[taskNum-1].passed){ 
            taskResults[taskNum-1].tries++;
            this.sumInfo.sumTries += 1;
            this.props.dataHub.saveTaskResult(this.props.supertaskName, taskNum, taskResults[taskNum-1]);
            this.setState({
                taskResults : taskResults,
                sumInfo : this.sumInfo
            });
        }
    }    

    // Фиксирует окончание полного тестирования задачи и обрабатывает результат этого тестирования
    testingFinishHandler = (taskNum, score, passed) => {
        let taskResults = this.state.taskResults;
        if( taskResults[taskNum-1].score < score || (!taskResults[taskNum-1].passed && passed) ){
            // Обновляем суммарную информацию
            this.sumInfo.sumScore += - taskResults[taskNum-1].score + score;
            this.sumInfo.tasksPassed += (!taskResults[taskNum-1].passed && passed) ? 1 : 0;
            
            // Обновляем результаты по задаче
            taskResults[taskNum-1].score = score;
            taskResults[taskNum-1].passed = passed;

            // Сохраняем
            this.props.dataHub.saveTaskResult(this.props.supertaskName, taskNum, taskResults[taskNum-1]);

            // Обновляем состояние
            this.setState({
                taskResults : taskResults,
                sumInfo : this.sumInfo
            });            
        }  

  }

    // При изменнии текста программы
    workspaceDOMChangedHandler = (taskNum, text)=>{
        this.taskData[taskNum - 1].workspaceDomText = text;
        this.props.dataHub.saveWorkspace(this.props.supertaskName, taskNum, text);
    }

    // При нажатии кнопки показа условия supertask
    showSupertaskStatement = () =>{
        this.setState({
            showStatement : true,
            statementModalTitle : 'Общая постановка задачи',
            statementModalSrc : this.supertaskStatementSrc
        });
    }

    // При нажатии кнопки показа условия task
    showTaskStatement = () =>{
        let curTaskNum = this.state.curTaskNum;
        this.setState({
            showStatement : true,
            statementModalTitle : 'Условие задачи № ' + curTaskNum,
            statementModalSrc : this.taskData[curTaskNum - 1].taskStatementSrc
        });
    }

    // При закрытии окна показа условия
    handleStatementClose = () => {
        this.setState({
            showStatement : false
        });
    }

    render(){
        return(
            <div className="Supertask">
                <div id = "supertaskScriptBlock"></div>
                {this.state.scriptLoaded && 
                    <InfoAndTaskSwitcherBlock
                        onShowSupertaskStatement = {this.showSupertaskStatement}
                        onShowTaskStatement = {this.showTaskStatement}                    
                        taskResults = {this.state.taskResults}
                        curTaskNum = {this.state.curTaskNum}
                        onTaskClick = {this.taskClickHandler}
                        sumInfo = {this.state.sumInfo}
                />}
                {this.state.scriptLoaded && 
                    <Task
                        ref = {this.taskRef}
                        supertask = {this.supertask}
                        taskData = {this.taskData[this.state.curTaskNum-1]}
                        onTestingStart = {this.testingStartHandler}
                        onTestingFinish = {this.testingFinishHandler}
                        onWorkspaceDOMChange = {this.workspaceDOMChangedHandler}
                />}
                <StatementModal
                    show={this.state.showStatement}
                    onClose={this.handleStatementClose}
                    title={this.state.statementModalTitle}
                    statementSrc={this.state.statementModalSrc}
                />
            </div>
        );
    }

    /*
        Tools
    */

   addZero(a){
       return a < 10 ? '0' + a : a.toString();
   }
}

export default Supertask;