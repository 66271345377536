import React from 'react';

import SceneControlPanel from './SceneControlPanel.js'
import SceneStatsPanel from './SceneStatsPanel.js'
import Scene from './Scene.js'

class SceneBlock extends React.Component{

    constructor(props){
        super(props);

        this.sceneRef = React.createRef();
    }

    getScene = () => {
        return this.sceneRef.current;
    }

    render(){
        return(
            <div id="sceneBlock">
                <SceneControlPanel
                    isProgramOnPause = {this.props.isProgramOnPause}
                    isProgramRunning = {this.props.isProgramRunning}
                    isProgramOnTesting = {this.props.isProgramOnTesting}
                    eventHandler = {this.props.eventHandler}
                    speed = {this.props.speed}/>
                <SceneStatsPanel 
                    testingResultInfo = {this.props.testingResultInfo}/>
                <Scene ref = {this.sceneRef}/>
            </div>
        );
    }
}

export default SceneBlock;