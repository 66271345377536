import React from 'react';
import './App.css';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import Button from 'react-bootstrap/Button';

import HeaderBlock from './toplevel/HeaderBlock.js'
import WorkBlock from './toplevel/WorkBlock.js'
import FooterBlock from './toplevel/FooterBlock.js'
import DataHub from './datatools/DataHub.js'
import HelpModal from './toplevel/HelpModal';

class App extends React.Component{

  constructor(props){
    super(props);

    //this.dataHub = new DataHub({clearLocalStorageAll : true, username:"test"});
    //this.dataHub = new DataHub({username:"testuser"});

    this.state = {
      logged : false
    };
  }


  normalizeUsername(username){
    let words = username.split(" ");
    let res = "";

    for(let i in words){
      if(words[i].length > 0){
        if(res.length > 0){
          res += ' ';
        }
        res += words[i].substring(0,1).toUpperCase() + words[i].substring(1).toLowerCase();
      }  
    }

    return res;
  }

  /*
    Handlers
  */

  handleLoginSubmit = (event) =>{
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    
    let username = this.normalizeUsername(form["username"].value);

    if(username.length > 0){
      this.dataHub = new DataHub({username:username});
      this.setState({
        logged : true,
        username : username
      });
    }    
  }

  handleLogoutClick = () => {
    this.setState({
      logged : false,
      username : undefined
    });
  }

  handleShowHelp = ()=>{
    this.setState({
      showHelp : true
    });
  }

  handleCloseHelp = ()=>{
    this.setState({
      showHelp : false
    });
  }

  render(){
    return (
      <div className="App">
        <Container fluid={true}>
          
          <Row>
            <Col xs={12} className="bg-primary">
              <HeaderBlock 
                logged = {this.state.logged} 
                username = {this.state.username}
                onHelpClick = {this.handleShowHelp}
                onLogoutClick = {this.handleLogoutClick}
              />
            </Col>
          </Row>

          <Row>

          {this.state.logged ?
            <Col xs={12}>
              <WorkBlock className="WorkBlock" dataHub = {this.dataHub}/>
            </Col>
            :
            (
              <Col xs={12}>
                <Row id="loginRow">
                  <Col xs={0} md={4}>
                  </Col>

                  <Col xs={12} md={4}>
                    <Card  id="loginForm">
                      <Card.Body>
                        <Form onSubmit={this.handleLoginSubmit}>
                          <Form.Group>
                            <Form.Label> Имя участника </Form.Label>
                            <Form.Control type="text" name="username" placeholder="Введите имя участника"></Form.Control>
                            <Form.Text className="text-muted">Для указанного имени в локальном хранилище браузера будут сохраняться результаты работы</Form.Text>
                          </Form.Group>
                          <Button type="submit">Войти</Button>
                        </Form>
                        </Card.Body>
                    </Card>
                  </Col>

                  <Col xs={0} md={4}>
                  </Col>
                </Row>
              </Col>        
            )
          }


          </Row>

          <Row>
            <Col xs={12} className="bg-gray-light align-self-center">
              <FooterBlock />
            </Col>
          </Row>
          
        </Container>

        <HelpModal
          show={this.state.showHelp}
          onClose={this.handleCloseHelp}
        />
      </div>
    );  
  }
  
}

export default App;
