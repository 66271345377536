import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

import GetUrl from '../datatools/GetUrl.js';
import InfoLoader from '../datatools/InfoLoader.js'

import SupertasksListItem from './SupertasksListItem.js'

class SupertasksList extends React.Component{

    constructor(props){
        /*
            props:
                supertasksNameList - список названий суперзадач
                onInfoLoaded(supertaskName, supertaskInfo) - вызывается по завершении загрузки информации по контесту
        */

        super(props);

        this.supertaskInfoList = this.props.namesList.map(
            (supertaskName) => {
                return {
                    supertaskName : supertaskName,
                    loaded : false,
                    itemVariant : ""
                }
            });

        this.totalScore = 0;
        this.totalTries = 0;
        this.totalPassed = 0;
        this.totalTasks = 0;
        this.state = {
            infoList : this.supertaskInfoList,
            totalScore : this.totalScore,
            totalTries : this.totalTries
        }
    }

    /*
        Lifecycle
    */

    componentDidMount(){
        this.loadSupertasksInfo();
    }

    /*
        Load data
    */

    loadSupertasksInfo = () => {
        for(let i = 0; i < this.supertaskInfoList.length; i++){
            InfoLoader.loadSupertaskInfo(this.supertaskInfoList[i].supertaskName, 
                (success, supertaskInfo) =>{
                    this.handleInfoLoaded(i, success, supertaskInfo);
            });
        }
    }

    /*
        Handlers
    */

    handleInfoLoaded(listInd, success, supertaskInfo){        

        if(success){

            this.supertaskInfoList[listInd].loaded = true;

            this.supertaskInfoList[listInd].title = (listInd + 1) + '. ' + supertaskInfo.title;

            if(supertaskInfo.logo){
                this.supertaskInfoList[listInd].logoSrc = 
                    GetUrl.getSupertaskRoot(this.supertaskInfoList[listInd].supertaskName) + supertaskInfo.logo;
            }

            this.supertaskInfoList[listInd].supertaskResult = 
                this.props.dataHub.getSupertaskResult(this.supertaskInfoList[listInd].supertaskName);
            this.totalScore += this.supertaskInfoList[listInd].supertaskResult.totalScore;
            this.totalTries += this.supertaskInfoList[listInd].supertaskResult.totalTries;
            this.totalPassed += this.supertaskInfoList[listInd].supertaskResult.totalPassed;

            if(supertaskInfo.tasksNum){
                // TODO сделать формирование списка результатов по задачам
                this.supertaskInfoList[listInd].tasksNum = supertaskInfo.tasksNum;
                this.totalTasks += supertaskInfo.tasksNum;
                let taskResults = [];
                for(let i = 0; i < supertaskInfo.tasksNum; i++){
                    taskResults.push(this.props.dataHub.getTaskResult(this.supertaskInfoList[listInd].supertaskName, i+1));
                }
                this.supertaskInfoList[listInd].taskResults = taskResults;

                if(this.supertaskInfoList[listInd].supertaskResult.totalPassed === supertaskInfo.tasksNum){
                    this.supertaskInfoList[listInd].statusClass = ' Passed';
                    this.supertaskInfoList[listInd].itemVariant = 'task-passed';
                }
                else if(this.supertaskInfoList[listInd].supertaskResult.totalTries > 0){
                    this.supertaskInfoList[listInd].statusClass = ' InProgress';
                    this.supertaskInfoList[listInd].itemVariant = 'task-in-progress';
                }
                else{
                    this.supertaskInfoList[listInd].statusClass = ' NotTried';
                    this.supertaskInfoList[listInd].itemVariant = 'task-not-tried';
                }              
            }

            // Передаём информацию наверх
            this.props.onInfoLoaded(this.supertaskInfoList[listInd].supertaskName, supertaskInfo);

        }
        else{
            this.supertaskInfoList[listInd].loadFailed = true;
            this.supertaskInfoList[listInd].title = "Ошибка загрузки данных";
        }

        this.setState({
            infoList : this.supertaskInfoList,
            totalScore : this.totalScore,
            totalTries : this.totalTries            
        });        
    }

    handleSupertaskClick = (supertaskName) => {
        this.props.onClick(supertaskName, 1);
    }

    handleTaskClick = (supertaskName, taskNum) => {
        this.props.onClick(supertaskName, taskNum);
    }

    render(){
        return (
            <Row>
                <Col xs={0} sm={1} md={2} lg={3}></Col>

                <Col xs={12} sm={10} md={8} lg={6}>
                    <ListGroup className="SupertasksList">
                        {this.state.infoList.map(
                            (item) =>
                            <ListGroup.Item key={item.supertaskName} 
                                variant={item.itemVariant}
                                onClick={item.loaded ? ()=>{this.handleSupertaskClick(item.supertaskName)} : ()=>{}}
                                >
                                
                                {item.loaded ?
                                    <SupertasksListItem
                                        title = {item.title}
                                        logoSrc = {item.logoSrc}
                                        supertaskResult = {item.supertaskResult}
                                        tasksNum = {item.tasksNum}
                                        taskResults = {item.taskResults}
                                        statusClass = {item.statusClass}
                                        onTaskClick = {(taskNum) => {this.handleTaskClick(item.supertaskName, taskNum);}}
                                    />
                                    :
                                    (item.loadFailed ? 'Ошибка загрузки данных'
                                        : 
                                        <Spinner animation="border"></Spinner>)
                                }
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item className="TotalOverSupertasks">
                            <label>Сумма баллов: </label>
                            <label className="TotalScore">{this.state.totalScore}</label>
                            <label>Количество попыток: </label>
                            <label className="TotalTries">{this.state.totalTries}</label>                            
                        </ListGroup.Item>
                    </ListGroup>
                </Col>

                <Col xs={0} sm={1} md={2} lg={3}>
                </Col>

            </Row>
        );
    }
}

export default SupertasksList;