import React from 'react';

import Button from 'react-bootstrap/Button';

class SceneControlPanel extends React.Component{

        handleSpeedChange = (event) => {
                this.props.eventHandler.onSpeedChange(event.target.value);
        }

        render(){      
                return(
                        <div className="SceneControlPanel">
                                <Button id="initButton"
                                        title="Инициализация"
                                        onClick={this.props.eventHandler.onInitPress}
                                        variant={this.props.isProgramRunning ? 'gray-light' : 'primary'}
                                        disabled={this.props.isProgramRunning}> ! </Button>                
                                <Button id="manualRunButton" 
                                        title="Выполнить"
                                        onClick={this.props.eventHandler.onManualRunPress}
                                        variant={this.props.isProgramOnTesting ? 'gray-light' : 'primary'}
                                        disabled = {this.props.isProgramRunning}> &gt; </Button>
                                <Button id="pauseButton" 
                                        title="Пауза"
                                        onClick={this.props.eventHandler.onPausePress}
                                        active = {this.props.isProgramOnPause}> || </Button>
                                <Button id="nextStepPressButton" 
                                        title="Следующая инструкция"
                                        onClick={this.props.eventHandler.onNextStepPress}
                                        variant={!this.props.isProgramRunning || !this.props.isProgramOnPause ? 'gray-light' : 'primary'}
                                        disabled={!this.props.isProgramRunning || !this.props.isProgramOnPause}> -&gt; </Button>
                                <Button id="runTestingPressButton"
                                        title="Выполнить тестирование"
                                        onClick={this.props.eventHandler.onRunTestingPress}
                                        variant={this.props.isProgramRunning && !this.props.isProgramOnTesting ? 'gray-light' : 'primary'}
                                        disabled={this.props.isProgramRunning}> &gt;&gt; </Button>
                                <Button id="stopPressButton" 
                                        title="Стоп"
                                        onClick={this.props.eventHandler.onStopPress}
                                        variant="dark"
                                        disabled={!this.props.isProgramRunning}> O </Button>
                                
                                <div id="speedBlock">
                                        <label id="speedLabel">Скорость: </label>
                                        <label> 1 </label>
                                        <input type="range" 
                                                id="speedInput" name="speed"
                                                min="1" max="10" value={this.props.speed}
                                                onChange = {this.handleSpeedChange}/>
                                        <label> 10 </label>
                                </div>
                        </div>
                );
        }
}

export default SceneControlPanel;