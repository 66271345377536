class GetUrl{

    static homepage = '';
    //static homepage = '/codestep';

    static getContestsListUrl(){
        return GetUrl.homepage + '/contests/contests.json';
    }

    static getContestRoot(contestName){
        return GetUrl.homepage + '/contests/' + contestName + '/';
    }

    static getSupertaskRoot(supertaskName){
        return GetUrl.homepage + '/supertasks/' + supertaskName + '/';
    }
}

export default GetUrl;