import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class ContestsListItem extends React.Component{
    render(){
        return(
            <div className="contestsListItem">
                <Row>
                    {this.props.logoSrc &&
                        <Col xs={this.props.logoSrc ? 12 : 0} md={this.props.logoSrc ? 2 : 0}>
                            <img className="ContestLogo"
                                src={this.props.logoSrc} 
                                alt="Нет логотипа"/>
                        </Col>
                    }
                    <Col xs={12} md={this.props.logoSrc ? 10 : 12}>
                        <h3>
                            {this.props.title}
                        </h3>
                        <p>
                            {this.props.desc}
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ContestsListItem;